import { useEffect, useState } from 'react';
import { fetchLegalDocument } from './utils';
import './LegalDocument.scss';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export default function LegalDocs({
  DocumentName,
  setFullScreenLoaderActiveBoolean,
  setShowRegOrDLModalBool,
}) {
  console.log('DOCUMENT NAME', DocumentName);
  const documentNameMatcher = {
    TermsOfUse: 'Saathi Terms of Use',
    PrivacyPolicy: 'Saathi Privacy Policy',
    SavingsTerms: 'Savings Account Terms and Conditions',
    Fees: 'Suryoday Saathi Account - Schedule of Charges',
    Grievances: 'Saathi Grievances',
  };
  let [legalDocObject, setLegalDocObject] = useState(null);

  useEffect(() => {
    //Get 'article' details from our API and then parse then:
    (async () => {
      setFullScreenLoaderActiveBoolean(true);
      console.log('Fetching Document: ' + documentNameMatcher[DocumentName]);
      await fetchLegalDocument(documentNameMatcher[DocumentName]).then((res) =>
        setLegalDocObject(res)
      );
      setFullScreenLoaderActiveBoolean(false);
    })();
  }, []);

  return (
    <main id='LegalDocument'>
      <section className='headerSection'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />
        <div className='title'>
          <h1>{documentNameMatcher[DocumentName].split('Saathi ')[1]}</h1>
          {legalDocObject && (
            <h5>UPDATED {legalDocObject.updatedAt?.split('T')[0]}</h5>
          )}
        </div>
      </section>

      <section className='documentContents'>
        {legalDocObject
          ? parse(
              DOMPurify.sanitize(legalDocObject.articleContent, {
                USE_PROFILES: { html: true },
              })
            )
          : null}
      </section>
    </main>
  );
}
