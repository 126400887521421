import { useState } from "react";
import "./ForDevelopersPage.scss";
import { HashLink } from "react-router-hash-link";
import userJourney from "./assets/userJourney.png";
// import widgetImports from './assets/widgetImports.png';
import widgetImportsNew from "./assets/widgetImportsNew.png";
// import productMapping from './assets/productMapping.png';
import productMappingNew from "./assets/productMappingNew.png";
import customColouring from "./assets/customColouring.png";
import buttonPositioning from "./assets/buttonPositioning.png";

export default function () {
  let [sectionPicked, setSectionPicked] = useState("Introduction");

  const sectionTitles = [
    "Introduction",
    "Save Now, Pay Later",
    "User Journey",
    "Implementation Prerequisites",
    "Javascript Implementation",
  ];
  sectionTitles.forEach((title) => {
    console.log(title.replaceAll(" ", "").replaceAll(",", ""));
  });

  return (
    <main id="ForDevelopersPage">
      <section className="topGradientContainer">
        <div className="radialPinkBlueGradient" />
        <div className="greyBlackLinearGradient" />

        <div className="titleArea">
          <h5>INTEGRATE WITH US</h5>
          <h1>Save Now Pay Later</h1>
          <p>
            Integrate our Save Now Pay Later solution in only a few days of
            <br />
            development time. Check out our integration guide below.
          </p>
        </div>
      </section>

      <section id="mainSection">
        <div id="sectionTitlesContainer">
          {sectionTitles.map((title, i) => (
            <HashLink
              className={
                "scrollLink" + (sectionPicked === title ? " active" : "")
              }
              onClick={() => setSectionPicked(title)}
              smooth
              to={`#${title.replaceAll(" ", "").replaceAll(",", "")}`}
              key={i}
            >
              {title}
            </HashLink>
          ))}
          <div
            id="sectionSelectedMarker"
            style={{
              top: sectionTitles.indexOf(sectionPicked) * 42 + "px",
            }}
          />
        </div>

        <div id="documentContainer">
          <section id="Introduction">
            <h4>What is Saathi?</h4>
            <p>
              Saathi is a neo-banking platform that offers rewarding savings to
              customers and helps them save for products, goods and services
              with popular merchants.
              <br />
              <br />
              There are multiple ways to integrate with Saathi including direct
              implementation, in app representation and shopify stores. We are
              very open to other integrations, just let us know what you need.
            </p>
          </section>

          <section id="SaveNowPayLater">
            <h4>Save Now, Pay Later</h4>
            <p>
              Save Now, Pay Later is a Saathi Product to help customers save
              against a goal in the context of a merchant purchase and encourage
              them to save up to buy the item they desire over a period of time.
              <br />
              <br />
              In the shopify and direct implementation, merchants can map one or
              more products against a simple button plug-in that is simple to
              include in your own e-commerce pages.
              <br />
              <br />
              Users tapping the 'Saathi Save' button will be asked to register
              for Saathi, have a savings goal created for them with their
              selected products and get a link to download the app.
              <br />
              <br />
              In the in-app implementation, you provide Saathi a list of
              products and offers and we'll represent your store in the app with
              the selected products for customers to choose. You can offer
              rewards or cashback and any number of products. Conversion is
              easiest in the app as the customer can browse and choose offers
              easily and link to their goals.
              <br />
              <br />
              Where you, the merchant, are offering the reward, in return,
              Saathi pays interest on savings balances, meaning your customers
              get a great deal on every purchase. Rewards, Interest and Cashback
              represent real cash offers to customers and a direct saving or
              bonus to their savings.
              <br />
              <br />
              As an example, if a customer saved Rs. 20,000 for a product and
              you offer a 10% reward and we apply 5% interest, the customer
              makes a saving of 15% in total. We encourage you to include any
              delivery costs against products to help ensure a customer saves
              enough. Remember, every customer has a Saathi Virtual card (Rupay)
              to complete their purchases.
            </p>
          </section>

          <section id="UserJourney">
            <h4>Saathi User Journey</h4>
            <p>
              The user journey is simple. A user chooses to 'save now, pay
              later', registers their goal and user account and gets the Saathi
              app to make deposits and track progress. When they have saved
              their goal amount, they simply use their virtual card to complete
              the purchase.
            </p>
            <img src={userJourney} alt="User journey flow" />
          </section>

          <section id="ImplementationPrerequisites">
            <h4>Implementation Prerequisites</h4>
            <p>Custom Javascript Implementation:</p>
            <ul>
              <li>Commercial agreement between merchant and Saathi</li>
              <li>
                Merchant details e.g. logo, URL, offer type and other elements
                to setup your access
              </li>
              <li>
                An e-commerce site, capable of embedding a javascript include
              </li>
              <li>
                A product page template for the javascript Saathi Save button
              </li>
              <li>
                Data mapping of your products to the minimum required fields
              </li>
              <li>
                Access to launch *saathi.money through your internet security
                setup
              </li>
              <li>Developer resources to create changes on your site</li>
            </ul>
            <p>Shopify Implementation:</p>
            <ul>
              <li>Commercial agreement between merchant and Saathi</li>
              <li>
                Merchant details e.g. logo, URL, offer type and other elements
                to setup your access
              </li>
              <li>Access to add a button to your product page template</li>
              <li>
                Access to run the admin panel widget for your site to select
                products to include
              </li>
              <li>
                Developer resources to create changes on your Shopify store
              </li>
            </ul>
            <p>In-App Implementation:</p>
            <ul>
              <li>Commercial agreement between merchant and Saathi</li>
              <li>
                Merchant details e.g. logo, URL, offer type and other elements
                to setup your access
              </li>
              <li>
                Data mapping of your products to allow them to be displayed in
                the app (including featured products)
              </li>
            </ul>
          </section>

          <section id="JavascriptImplementation">
            <h4>Custom Javascript Implementation</h4>
            <p>
              There are 3 steps to implement Saathi in your custom site, we make
              no assumptions here as to server-side or client-side solutions,
              but most implementations generate server-side components and so
              this should be included into page templates on the server side
              code; if you have a client-side set of components, then the same
              code can work here too.
            </p>
            <h5>Step 1: Choose Environment</h5>
            <p>
              We offer a development and production environment.
              <br />
              <br />
              For development: use saathi-snpl-widget.bundle located at
              <br />
              <span>
                https://snplwidget.s3.ap-south-1.amazonaws.com/build-devtwo/saathi-snpl-widget.bundle.js
              </span>
              <br />
              <br />
              For production: use saathi-snpl-widget.bundle.js located at
              <br />
              <span>
                https://snplwidget.s3.ap-south-1.amazonaws.com/build-production/saathi-snpl-widget.bundle.js
              </span>
            </p>

            <h5>Step 2: Include the Saathi Widget script and fonts:</h5>
            {/* <img src={widgetImports} /> */}
            <img src={widgetImportsNew} alt="Widget Imports" />
            <p>
              The fonts and script tag simply ensure that the modal dialog uses
              our experience standards and integrates the react functionality of
              the widget itself. We recommend placing this in your page template
              header.
            </p>

            <h5>Step 3: Map your product data to our goal data items:</h5>
            {/* <img src={productMapping} /> */}
            <img src={productMappingNew} alt="Product mapping example" />

            <h5>Step 4 (optional): Customise the button's colouring:</h5>
            <img src={customColouring} alt="Custom colouring example" />

            <h5>Step 5 (optional): Customise the button's size/positioning:</h5>
            <img src={buttonPositioning} alt="Button customisation example" />
          </section>
        </div>
      </section>
    </main>
  );
}
