import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'src/sharedComponents/Forms/InputField';
import { classify } from 'src/utils';
import Mixpanel from 'mixpanel-browser';
import SignUpPageTemplate from './SignUpPageTemplate';
import { signupDataContext } from './SignupDataProvider';
import { initialiseSignup } from './utils';

const SavingsProgrammeEnterDetails = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, setSignUpInfo, setOtp, setPin } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    // reset info if the user pressed back from OTP page:
    setOtp('');
    setPin('');
    setSignUpInfo({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      currentSavings: '',
      savingFor: '',
      gainFromProgramme: '',
      referralCode: '',
      isExistingBankCustomer: false,
    });
  }, []);

  // Validate only alphabetic characters
  const handleAlphaInputChange =
    (field: 'firstName' | 'lastName') => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      // Allow only alphabetic characters
      const regex = /^[a-zA-Z]*$/;
      if (regex.test(value)) {
        setSignUpInfo({
          ...signUpInfo,
          [field]: value,
        });
      }
    };

  async function processSubmit() {
    if (signUpInfo.phoneNumber.length !== 10)
      return setErrorMessage('Mobile phone number must be 10 digits long.');

    setIsSubmitting(true);
    Mixpanel.track("registration_sbl_enter_details");
    Mixpanel.track('Text box - Email', { value: signUpInfo?.email });
    Mixpanel.track('Text box - Mobile', { value: signUpInfo?.phoneNumber });
    Mixpanel.track('is_existing_bank_customer', { value: signUpInfo?.isExistingBankCustomer, phoneNumber: signUpInfo?.phoneNumber });
    try {
      await initialiseSignup(signUpInfo).then(() => {
        navigate('/suryoday/SavingsProgramme/EnterOTP');
      });
    } catch (err) {
      console.log('ERROR HITTING REG INITIALISTION', err);
      setErrorMessage(err);
      setIsSubmitting(false);
    }
  }

  return (
    <SignUpPageTemplate
      heading='Create your account'
      subHeading='Let’s get started. Share your few details for account set up:'
      onSubmit={processSubmit}
      isSubmitting={isSubmitting}
      deviceType={deviceType}
      errorMessage={errorMessage}
      showBackButton={false}
    >
      <InputField
        label='First Name*'
        autoCapitalize='words'
        autoComplete='given-name'
        pattern='[a-zA-Z]+'
        autoFocus
        required
        defaultValue={signUpInfo.firstName}
        value={signUpInfo.firstName}
        onChange={handleAlphaInputChange('firstName')}
      />

      <InputField
        label='Last Name*'
        autoCapitalize='words'
        autoComplete='family-name'
        pattern='[a-zA-Z]+'
        required
        defaultValue={signUpInfo.lastName}
        value={signUpInfo.lastName}
        onChange={handleAlphaInputChange('lastName')}
      />

      <InputField
        label='Email*'
        type='email'
        autoComplete='email'
        required
        defaultValue={signUpInfo.email}
        value={signUpInfo.email}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            email: e.target.value,
          });
        }}
      />

      <InputField
        label='Mobile Number*'
        type='tel'
        pre='+91'
        autoComplete='tel'
        required
        defaultValue={signUpInfo.phoneNumber}
        value={signUpInfo.phoneNumber}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            phoneNumber: e.target.value,
          });
        }}
      />

      <InputField
        label='Referral Code'
        type='text'
        defaultValue={signUpInfo.referralCode}
        value={signUpInfo.referralCode}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            referralCode: e.target.value,
          });
        }}
      />

      <div>
        <label htmlFor="existingBankCustomer" className='w-full block appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl mb-2'>Are You an Existing Suryoday Bank Customer?</label>
        <div className="relative peer h-16 flex items-center w-full flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
          <nav className="flex w-full h-full min-w-[240px] flex-row gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">

            <div className="flex ml-[25%] items-center gap-3 w-full p-0 leading-tight transition-all rounded-lg outline-none text-start">
              <input
                checked={signUpInfo.isExistingBankCustomer === true}
                type="radio"
                name="hs-default-radio"
                className="shrink-0 h-5 w-5 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-existingCustomer-yes"
                onChange={(e) => {
                  setSignUpInfo({
                    ...signUpInfo,
                    isExistingBankCustomer: e.target.checked
                  });
                }}
              />
              <p className="block font-telegrafSemiBold text-base antialiased font-medium leading-relaxed lg:text-xl text-blue-gray-400">
                Yes
              </p>
            </div>

            <div className="flex items-center gap-3 w-full p-0 leading-tight transition-all rounded-lg outline-none text-start">
              <input
                checked={signUpInfo.isExistingBankCustomer === false}
                type="radio"
                name="hs-default-radio"
                className="shrink-0 h-5 w-5 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-existingCustomer-no"
                onChange={(e) => {
                  setSignUpInfo({
                    ...signUpInfo,
                    isExistingBankCustomer: !e.target.checked
                  });
                }}
              />
              <p className="block font-telegrafSemiBold text-base antialiased font-medium leading-relaxed lg:text-xl text-blue-gray-400">
                No
              </p>
            </div>

          </nav>
        </div>
      </div>

      <p
        className={classify([
          '!text-xs',
          'ml-6 font-telegrafRegular text-xs leading-[1.1] text-black/50',
        ])}
      >
        Saathi is currently only available to residents of India
      </p>

      <label className='relative flex cursor-text flex-row-reverse gap-x-3 rounded-2xl bg-white px-6 shadow-md'>
        <select
          name='savings'
          id='savings'
          className={classify([
            'peer block h-16 w-full appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl',
          ])}
          onChange={(e) => {
            setSignUpInfo({
              ...signUpInfo,
              currentSavings: e.target.value,
            });
          }}
        >
          <option disabled selected className='opacity-40'>
            Please select an option...
          </option>

          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </select>

        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            'font-saathiUltraBold',
          ])}
        >
          Do you have a Savings habit?
        </span>
      </label>

      <label className='relative flex cursor-text flex-row-reverse gap-x-3 overflow-hidden rounded-2xl bg-white px-6 shadow-md'>
        <select
          name='what'
          id='what'
          className={classify([
            'peer block h-16 w-full appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl',
          ])}
          onChange={(e) => {
            setSignUpInfo({
              ...signUpInfo,
              savingFor: e.target.value,
            });
          }}
        >
          <option disabled selected>
            Please select an option...
          </option>

          <option value='short-term'>
            For a short term goal - such as buying a gadget, or saving for a holiday
          </option>

          <option value='medium-long'>
            For a medium/long term goal (e.g. house, marriage, education, retirement)
          </option>

          <option value='emergencies'>For emergencies - health, general</option>
        </select>

        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            'font-saathiUltraBold',
          ])}
        >
          What are you saving for / would you like to save for?
        </span>
      </label>

      <label className='relative flex cursor-text flex-row-reverse gap-x-3 rounded-2xl bg-white px-6 shadow-md'>
        <select
          name='savings'
          id='savings'
          className={classify([
            'peer block h-16 w-full appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl',
          ])}
          onChange={(e) => {
            setSignUpInfo({
              ...signUpInfo,
              gainFromProgramme: e.target.value,
            });
          }}
        >
          <option disabled selected>
            Please select an option...
          </option>

          <option value='yes'>Build a savings habit</option>
          <option value='yes'>Get rewards/discounts on future purchases</option>
          <option value='yes'>Unsure, just keen to explore</option>
        </select>

        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            'font-saathiUltraBold',
          ])}
        >
          What would you like to gain from this programme?
        </span>
      </label>

      <p className={classify(['!text-xs', 'ml-6 mt-2 font-telegrafRegular text-xs leading-[1.1]'])}>
        By clicking "Continue", you are acknowledging and agreeing to this disclaimer and the
        subsequent use of Saathi.
      </p>

      <p className={classify(['!text-xs', 'ml-6 font-telegrafRegular text-xs leading-[1.1]'])}>
        You acknowledge that for the provision of these products and services, Suryoday Small Finance Bank Ltd may
        need to share your data available with Suryoday Small Finance Bank Ltd with Saathi. By opting in to this
        programme, you authorize Suryoday Small Finance Bank Ltd to share your data and customer information with
        Saathi to enable the provision of the aforementioned products and services.
      </p>

      <p className={classify(['!text-xs', 'ml-6 font-telegrafRegular text-xs leading-[1.1]'])}>
        You can find the detailed T&C over here:{' '}
        <a
          target='_blank'
          href='https://www.saathi.money/LegalDocs/TermsOfUse'
          className='underline'
        >
          https://www.saathi.money/LegalDocs/TermsOfUse
        </a>
      </p>
    </SignUpPageTemplate>
  );
};
export default SavingsProgrammeEnterDetails;
