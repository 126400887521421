import successImg from '../../assets/images/flexxpay_success.png';
import { useEffect } from 'react';
import './flexxpay.scss';

function onClose() {
  window.closeCashfreeModal();
}

export default function FlexxPayCashfreeSuccess({}) {
  // Assign success or faliure variable as soon as page loads (so that my Flexxpay widget can access it regardless if they click the 'Close' button here on manually close the modal in my widget)
  useEffect(() => {
    window.cashfreeResult = 'success';
  }, []);

  return (
    <section id="flexxpay_success">
      <p className="centerAlign">
        Yes! Successfully created your
        <br />
        <b>FlexxPay subscription!</b>
        <br />
      </p>

      <img src={successImg} alt="Subscription success" />

      <div>
        <button onClick={onClose}>Close</button>
      </div>

      <p className="smallText">
        Any queries, please contact{' '}
        <a href="https://mailto:support@besaathi.com">support</a>.
      </p>
    </section>
  );
}
