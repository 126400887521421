import './FullScreenLoader.scss';

export const LoadingRing = () => {
  return (
    <div id="LoadingRing">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const FullScreenLoader = () => {
  return (
    <div id="FullScreenLoader">
      <LoadingRing />
    </div>
  );
};
