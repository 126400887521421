export type TextWeight = 'regular' | 'semiBold' | 'bold';

const weights: { [key in TextWeight]: string } = {
  regular: 'font-telegrafRegular',
  semiBold: 'font-telegrafSemiBold',
  bold: 'font-saathiUltraBold',
  // caveat: caveatBold,
};

export const useTextWeight = (weight: TextWeight) => weights[weight];
