import {
  default as devEarlyAccessQR,
  default as prodEarlyAccessQR,
} from '../../assets/images/dev_early_access.svg';
import './EarlyAccessWeb.scss';
// ^ SHOULD HAVE PROD OPTION FOR: saathi.money/earlyAccess as well (not just pointing straight to dev)

const webQRCodeImage = process.env.REACT_APP_DEV === 'true' ? devEarlyAccessQR : prodEarlyAccessQR;

export default function EarlyAccessWeb({}) {
  console.log('IS BETA?', process.env.REACT_APP_BETA_QR);

  return (
    <section id='earlyaccess'>
      <p className='centerAlign'>
        <b>We noticed you are trying to download apps from a desktop browser.</b>
        <br />
        <br />
        You need to access this on your device, use this handy QR code to do so:
        <br />
      </p>

      <img
        id='qrCode'
        src={webQRCodeImage}
        alt={'QR Code for Saathi App'}
        className='mx-auto mt-6 !w-64'
      />

      <p className='smallText'>
        Any queries, please contact <a href='https://mailto:support@besaathi.com'>support</a>{' '}
      </p>
    </section>
  );
}
