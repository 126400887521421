import './delete.scss';
import handUpPNG from '../../assets/icons/handUp.png';
import closePNG from '../../assets/icons/close.png';

export default function Delete({ setShowDeleteModalBool }) {
  let email = 'support@besaathi.com';
  let phone = '+91 80 694 90468';
  let chat =
    "Chat to us by tapping the pink 'Meet Saathi' button on any page on this website or the chat icon in the app.";

  return (
    <div id='modalContainer'>
      <div id='opacifyingDiv'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />
      </div>
      <div id='RegisterModal'>
        <img
          alt='Close icon'
          src={closePNG}
          id='closePNG'
          onClick={() => null} //setShowDeleteModalBool(false)}
        />
        <img src={handUpPNG} id='handUpPNG' alt='Hand up icon' />
        <h1>Close Account</h1>
        <p>
          We are sorry you want to close your account. The official route for
          doing this is to contact our team by phone, mail or chat. Please
          follow this one of these routes to close your account.
          <br />
        </p>

        <div id='contactDetailsGrid'>
          <div className='label'>
            <span>Email:</span>
          </div>
          <div className='data'>
            <a href={`mailto:${email}`}>{email}</a>
          </div>

          <div className='label'>
            <span>Phone:</span>
          </div>
          <div className='data'>
            <span>{phone}</span>
          </div>

          <div className='label'>
            <span>Chat:</span>
          </div>
          <div className='data'>
            <span>{chat}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
