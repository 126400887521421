//

import React from 'react';
import whatsapp from '../../../assets/icons/whatsapp.svg';
import chatBotGfx from '../../../assets/images/chat-bot.svg';
import communityUi from '../../../assets/images/community-ui.jpg';

import './style.scss';
import mixpanel from 'mixpanel-browser';

export default function Hero({}) {
  const _onClick = () => {
    mixpanel.track("join_WA_community_btn_clicked");
  }
  const SaathiSavingsClub = () => {
    return (
      <>
        <a href='https://chat.whatsapp.com/HeccccRC7cb445xAidrOdz' target='_blank' className='join-whatsapp'>
          <div onClick={_onClick} className='join-whatsapp-button'>
            <img
              src={whatsapp}
              alt='Discord logo'
              height='24'
              id='discordLogo'
            />
            <span>&nbsp;Join us on WhatsApp</span>
          </div>
        </a>
        <img
          src={communityUi}
          alt='Join Saathi Savings Club on Discord'
        />
      </>
    );
  };

  return (
    <section className='hero'>
      <div className='wrap container'>
        <div className='content'>
          <a href='https://chat.whatsapp.com/HeccccRC7cb445xAidrOdz' target='_blank' className='notice'
          id='notice'>
              <img
                src={whatsapp}
                alt='WhatsApp Icon'
                height='20'
                id='WhatsAppIcon'
              />
              <span>&nbsp;Join us on WhatsApp</span>
          </a>
          <h1>#TakeOff with Saathi Savings Club ✈️</h1>
          <p>
            Unlock a world of financial empowerment and rewards by joining our exclusive Savings Club. At Saathi, we believe in making savings not just a habit, but a lifestyle. Start Flexxing today!
          </p>
        </div>
        
        <div className='graphic'>
          <SaathiSavingsClub />
        </div>
      </div>
    </section>
  );
}
