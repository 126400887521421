/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// DEVTWO (development):
// const awsExports = {
//   aws_project_region: 'ap-south-1',
//   aws_appsync_graphqlEndpoint:
//     'https://hmk2gcgvynb7hljueyctukf3ri.appsync-api.ap-south-1.amazonaws.com/graphql',
//   aws_appsync_region: 'ap-south-1',
//   aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//   aws_appsync_apiKey: 'da2-fsixpbsj7zcpzbptzzcpiaamlu',
//   aws_cloud_logic_custom: [
//     {
//       name: 'OTPEmailApi',
//       endpoint: 'https://9lgc8qdzz3.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'wrapper',
//       endpoint: 'https://fxsgvkz7be.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'cognitoUsersAPI',
//       endpoint: 'https://ohr1ju6yoh.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'OrgAndEmpWithdrawalCalcsAPI',
//       endpoint: 'https://ms06tu6y2d.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'saathiAPI',
//       endpoint: 'https://r6ptacq392.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'suryodayREST',
//       endpoint: 'https://3na96744ee.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'andrewsapi',
//       endpoint: 'https://o24rrwrbgh.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//     {
//       name: 'cashfreeRest',
//       endpoint: 'https://3j44bbjltk.execute-api.ap-south-1.amazonaws.com/devtwo',
//       region: 'ap-south-1',
//     },
//   ],
//   aws_cognito_identity_pool_id: 'ap-south-1:144fe625-2e51-4081-b230-0e3d6f48d981',
//   aws_cognito_region: 'ap-south-1',
//   aws_user_pools_id: 'ap-south-1_BCpZs41kX',
//   aws_user_pools_web_client_id: 'n5ofu9sjktc09gcd7s2cb19sq',
//   oauth: {},
//   aws_cognito_username_attributes: [],
//   aws_cognito_social_providers: [],
//   aws_cognito_signup_attributes: ['EMAIL', 'PHONE_NUMBER'],
//   aws_cognito_mfa_configuration: 'OPTIONAL',
//   aws_cognito_mfa_types: ['SMS', 'TOTP'],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 6,
//     passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
//   },
//   aws_cognito_verification_mechanisms: ['EMAIL', 'PHONE_NUMBER'],
//   aws_user_files_s3_bucket: 'saathi-dev2',
//   aws_user_files_s3_bucket_region: 'ap-south-1',
// };

// PROPANE BURNER (production):
const awsExports = {
  aws_project_region: 'ap-south-1',
  aws_appsync_graphqlEndpoint:
    'https://h34iyystxfdqfhnjihbdipyu4q.appsync-api.ap-south-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-south-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-owoj3jawmfclfdgat3p2rpah3u',
  aws_cloud_logic_custom: [
    {
      name: 'OTPEmailApi',
      endpoint: 'https://evsvkliin0.execute-api.ap-south-1.amazonaws.com/burner',
      region: 'ap-south-1',
    },
    {
      name: 'wrapper',
      endpoint: 'https://bvg91qz3ql.execute-api.ap-south-1.amazonaws.com/burner',
      region: 'ap-south-1',
    },
    {
      name: 'cognitoUsersAPI',
      endpoint: 'https://6zreimyyp9.execute-api.ap-south-1.amazonaws.com/burner',
      region: 'ap-south-1',
    },
    {
      name: 'OrgAndEmpWithdrawalCalcsAPI',
      endpoint: 'https://qnw5xb6dfa.execute-api.ap-south-1.amazonaws.com/burner',
      region: 'ap-south-1',
    },
    {
      name: 'saathiAPI',
      endpoint: 'https://7zqmhhz9wj.execute-api.ap-south-1.amazonaws.com/burner',
      region: 'ap-south-1',
    },
    {
      name: 'suryodayREST',
      endpoint: 'https://mp5312jk0b.execute-api.ap-south-1.amazonaws.com/burner',
      region: 'ap-south-1',
    },
  ],
  aws_cognito_identity_pool_id: 'ap-south-1:3e14220c-5c61-4707-ac28-1032308aec75',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_fljUuJSWw',
  aws_user_pools_web_client_id: '1ufdjvsfd00lf55ulcnna589ba',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 6,
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL', 'PHONE_NUMBER'],
  aws_user_files_s3_bucket: 'saathi-propane',
  aws_user_files_s3_bucket_region: 'ap-south-1',
};

export { awsExports };
