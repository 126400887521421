import laptopDeal from "./assets/merchant-browser.png";
import imgCustomisable from "./assets/customisable.svg";
import imgEngagement from "./assets/engagement.svg";
import imgExperience from "./assets/experience.svg";
// import imgPlatformList from './assets/platform-list.svg';
import imgPlatformList from "./assets/worksWith.png";
import imgScript from "./assets/script.png";
import "./ForBusinessesPage.scss";
// import BrandLogosSection from './brandLogosSection/BrandLogosSectionOld';
import BrandLogosSection from "./brandLogosSection/BrandLogosSection";
import { useNavigate } from "react-router-dom";

export default function ForBusinesses({
  setShowRegOrDLModalBool,
  deviceType,
  setShowContactUsModalBool,
}) {
  const navigate = useNavigate();
  console.log("setShowContactUsModalBool " + setShowContactUsModalBool);

  return (
    <main id="MerchantPage">
      <section id="hero-section">
        <div className="radialPinkBlueGradient" />
        <div className="whiteningLinearGradient" />

        <div class="grid-container">
          <div className="leftHandSide">
            <h5>SAATHI FOR MERCHANTS</h5>
            <h1>Give customers rewards for saving</h1>
            <p>
              We help our users build a positive relationship with money by
              rewarding them for saving. Be part of the movement and offer
              rewards for your products through Saathi
            </p>
            <img
              id="imgPlatformList"
              src={imgPlatformList}
              alt="Platform Icon"
            />

            {deviceType === "desktop" && (
              <button
                id="findOutMoreButton"
                onClick={() => setShowRegOrDLModalBool(true)}
              >
                Find out more
              </button>
            )}
          </div>
          <img
            id="laptopDeal"
            src={laptopDeal}
            alt="Merchant laptop offer example"
          />
        </div>
      </section>

      <section id="whySaathi">
        <div className="subSection grid-container" id="whySaathiHeaderSection">
          <h2>Why Saathi, and why Save Now, Pay Later?</h2>
          <p>
            We see our role as one of helping our users build a positive
            relationship with their money. By encouraging users to save for that
            special purchase, we foster a better financial responsibility and
            promote healthy savings habits.
            <br />
            <br />
            By working with us to reward saving, you can become part of the next
            generation of conscious consumerism.
          </p>
        </div>

        <div className="subSection grid-container" id="graphicTabsSection">
          <div className="graphicTab">
            <img src={imgExperience} alt="Enhance the buying experience" />
            <h4>Enhance the buying experience</h4>
            <p>
              Enable customers to engage with your brand while they save,
              increasing trust and loyalty, while reducing advertising and
              retargeting costs.
            </p>
          </div>
          <div className="graphicTab">
            <img src={imgCustomisable} alt="Create savings goals" />
            <h4>Deepen Engagement</h4>
            <p>
              Your customers can create savings goals linked to your products
              while you capture purchase intent and reward the action of saving
              to buy.
            </p>
          </div>
          <div className="graphicTab">
            <img src={imgEngagement} alt="Customisable solution" />
            <h4>Fully Customisable</h4>
            <p>
              Select one product, or all to include for Saathi Save, tailor
              reward levels to individual products and set the Saathi button in
              your style.
            </p>
          </div>
        </div>

        <div className="subSection" id="installationSection">
          <div className="grid-container">
            <div className="leftHandSide">
              <h4>Installation is quick & easy</h4>
              <p>
                Check out our developer documentation to find out how you can
                get started with Saathi and bring Save Now, Pay Later to your
                customers.
              </p>
              <button
                id="discoverDocsButton"
                onClick={() => navigate("/Resources/ForDevelopers")}
              >
                Discover the Docs
              </button>
            </div>
            <img src={imgScript} alt="Discover Developer docs" />
          </div>
        </div>

        <div className="subSection" id="brandsSection">
          <div className="leftHandSide">
            <h4>
              Join brands that are driving increased loyalty and promoting
              responsible consumption
            </h4>
            <p>
              We work with brands that are looking to create long-lasting and
              impactful relationships with their customers.
            </p>
          </div>

          {/* <img src={brandLogosPNG} /> */}
          <BrandLogosSection />
        </div>
      </section>

      {/* <section id="register"></section> */}
    </main>
  );
}
