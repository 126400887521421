import { classify } from 'src/utils';
import { TextAlign, useTextAlign } from './useTextAlign';
import { TextAlpha, useTextAlpha } from './useTextAlpha';
import { useTextColor } from './useTextColor';
import { TextTransform, useTextTransform } from './useTextTransform';
import { TextWeight, useTextWeight } from './useTextWeight';
import { TextWrap, useTextWrap } from './useTextWrap';

const variants = {
  size: {
    '2xs': 'text-xs -my-1',
    xs: 'text-xs lg:text-sm -my-1',
    sm: 'text-sm lg:text-base -my-1',
    base: 'lg:text-lg -my-1 lg:-my-2',
    md: 'text-lg lg:text-xl -my-1 lg:-my-2',
    lg: 'text-xl lg:text-2xl -my-1 lg:-my-2',
    xl: 'text-2xl lg:text-3xl -my-1 lg:-my-2',
    '2xl': 'text-3xl lg:text-4xl -my-1 lg:-my-2',
  },
};

export interface Props extends React.HTMLAttributes<HTMLParagraphElement>, React.PropsWithChildren {
  size?: keyof typeof variants.size;
  color?: Color;
  weight?: TextWeight;
  align?: TextAlign;
  transform?: TextTransform;
  wrap?: TextWrap;
  alpha?: TextAlpha;
  overlay?: number;
}

const Text = ({
  size = 'base',
  color = 'purple',
  weight,
  align,
  transform,
  wrap,
  alpha,
  overlay,
  className,
  children,
  ...props
}: Props) => {
  const _className = classify([
    variants.size[size],
    useTextColor(color),
    useTextWeight(weight),
    useTextAlign(align),
    useTextTransform(transform),
    useTextWrap(wrap),
    useTextAlpha(alpha),
    overlay ? 'mix-blend-overlay' : undefined,
    className,
  ]);

  return (
    <div className='relative'>
      <p className={_className} {...props}>
        {children}
      </p>

      {Array.from({ length: overlay }, (_, i) => (
        <span className={classify([_className, 'absolute inset-0'])} key={i}>
          {children}
        </span>
      ))}
    </div>
  );
};

export default Text;
