import akbarTravels from '../../../../assets/icons/akbarTravels.svg';
import holidayTribe from '../../../../assets/icons/holidayTribe.svg';
import candere from '../../../../assets/icons/candere.svg';
import shoppersStop from '../../../../assets/icons/shoppersStop.svg';
import acer from '../../../../assets/icons/acer.svg';
import uniqlo from '../../../../assets/icons/uniqlo.svg';
import pepperfry from '../../../../assets/icons/pepperfry.svg';
import nykaa from '../../../../assets/icons/nykaa.svg';
import CategoryCards from './CategoryCards';

const EarnRewardsOn = ({ deviceType, merchantLogosSectionRef }) => {
  const merchantImages = [
    akbarTravels,
    candere,
    shoppersStop,
    holidayTribe,
    uniqlo,
    pepperfry,
    nykaa,
    acer,
  ];

  return (
    <div className='pt-14'>
      <h5 className='mb-4 px-5 text-center text-2xl sm:text-5xl'>It’s a rewarding journey!</h5>
      <p className='mb-16 text-center text-base sm:text-2xl m-auto w-full max-w-[920px]'>
        Be it for sending money home, pursuing a 👩‍🎓 postgraduate degree for a salary hike, 🏝️ enjoying a short trip with friends, or embarking on a 🏍️💨 biking adventure, you get rewarded as you achieve your goal.
      </p>
      <CategoryCards deviceType={deviceType} />
      
      <h5 className='mb-16 px-5 text-center text-2xl sm:text-4xl' id='merchants'>Merchant Reward Partners</h5>
      <div ref={merchantLogosSectionRef} className='m-auto max-w-[1200px] px-8 md:px-16'>
        <div className='grid grid-cols-2 gap-11 sm:grid-cols-4 sm:gap-[72px]'>
          {merchantImages.map((img, i) => {
            return <img src={img} className='w-60 max-h-12' key={i} />;
          })}
        </div>
      </div>
      <p className='mt-16 text-center text-base sm:text-2xl m-auto w-full max-w-[920px]'>...and many more on the platform</p>
    </div>
  );
};

export default EarnRewardsOn;
