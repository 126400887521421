import teamProfiles from "./teamProfiles";
import "./TeamProfileIcons.scss";

export default function TeamProfiles() {
  return (
    <div id="profileIconsGrid">
      {teamProfiles.map(({ name, dept }, i) => (
        <div className="userSquare" key={i}>
          <div
            id="profileImage"
            style={{
              backgroundImage: `url(${require("./teamProfilePictures/" +
                name +
                ".jpg")})`,
            }}
          />
          <span id="name">{name}</span>
          <span id="dept">{dept}</span>
        </div>
      ))}
    </div>
  );
}
