import { ReactComponent as QuotesSVG } from '../../../assets/icons/quotes.svg';
import './ProductFeedbacks.scss';

const productFeedbacks = [
  {
    name: 'Shefali, Mumbai',
    reason: 'Saving for a holiday of a lifetime',
    message:
      "I really like the idea of saving with my friends for a holiday, it's so unique!",
  },
  {
    name: 'Harshal, Mumbai',
    reason: 'Saving for a new computer',
    message: 'I am definitely going to use Saathi to by my next Laptop”',
  },
  {
    name: 'Anant, Delhi',
    message: 'Great idea and helps me create a positive habit :)',
  },
  {
    name: 'Gauthami, Udaipur',
    message:
      'Good concept. Will ease the process of saving because no manual planning required',
  },
  {
    name: 'Karl, Mumbai',
    message:
      'Seems as though a great rewards experience. Pot savings - keep add money and get rewarded. Really like the idea',
  },
  {
    name: 'Manoj, Delhi',
    message:
      'It is good that we can earn interest and buy product at cheaper rate',
  },
];

function FeedbackCard({ name, reason, message }) {
  return (
    <div className="feedbackCard">
      <QuotesSVG />
      <p className="message">{message}</p>
      <span className="name">{name}</span>
      <span className="savingReason">{reason}</span>

      <div className="gradientEffect" />
    </div>
  );
}

export default function () {
  return (
    <>
      <h1 className="sectionHeader">The kind of credit we like</h1>
      <p className="headerCaption">
        We have some amazing early-adopters and they have some even more amazing
        things to say about saving with Saathi.
      </p>

      <div className="centeringDiv">
        <div id="feedbackCardsContainer">
          {productFeedbacks.map((feedback, i) => (
            <FeedbackCard {...feedback} key={i} />
          ))}
        </div>
      </div>
      <div className="radialPinkBlueGradient" />
      <div className="whiteningLinearGradient" />
    </>
  );
}
