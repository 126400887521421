import { useState, useEffect, Fragment } from 'react';
// import DownloadSaathiFooter from '../../../sharedComponents/DownloadSaathiFooter/DownloadSaathiFooter';
import './FAQPage.scss';
import QuestionsAndAnswers from './QuestionsAndAnswers';

export default function ({
  deviceType,
  setShowRegOrDLModalBool,
  setShowContactUsModalBool,
}) {
  let [questionSectionPicked, setQuestionSectionPicked] =
    useState('General Questions');

  return (
    <main id='FAQPage'>
      <section className='topGradientContainer'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />

        <div className='titleArea'>
          <h5>LEARN MORE</h5>
          <h1>
            Frequently
            <br />
            <span>Asked Questions</span>
          </h1>
          <p>
            Got a question?
            <br />
            Find an answer, and if we don't have your answer here,
            <br />
            we'd love to hear from you directly.
          </p>
          <br />
          <br />
        </div>
      </section>

      <section id='questionsAnswersSection'>
        {deviceType === 'desktop' && (
          <div id='sectionTitlesContainer'>
            {Object.keys(QuestionsAndAnswers).map((key, i) => (
              <h5
                style={
                  questionSectionPicked === key
                    ? {
                        backgroundColor: '#d6e9fd',
                      }
                    : null
                }
                onClick={() => setQuestionSectionPicked(key)}
                key={i}
              >
                {key}
              </h5>
            ))}
          </div>
        )}

        <div id='QAsContainer'>
          {
            // If on desktop show nav bar on left:
            deviceType === 'desktop'
              ? QuestionsAndAnswers[questionSectionPicked].map(
                  ({ question, answer }, key) => (
                    <Fragment key={key}>
                      <h5>{question}</h5>
                      {answer}
                    </Fragment>
                  )
                )
              : // Else if on mobile then dont show nav bar but show all sections at same time:
                Object.keys(QuestionsAndAnswers).map((category, key) => {
                  return (
                    <Fragment key={key}>
                      {/* If on mobile then display category headings too as side bar is hidden: */}
                      {deviceType === 'mobile' && <h3>{category}:</h3>}
                      {QuestionsAndAnswers[category].map(
                        ({ question, answer }, key) => (
                          <Fragment key={key}>
                            <h5>{question}</h5>
                            {answer}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  );
                })
          }
        </div>
      </section>

      {/* <DownloadSaathiFooter setShowRegOrDLModalBool={setShowRegOrDLModalBool} /> */}
    </main>
  );
}
