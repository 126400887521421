import { useNavigate } from 'react-router-dom';
import { Heading } from 'src/sharedComponents/Typography/Heading';
import Text from 'src/sharedComponents/Typography/Text';
import { classify } from 'src/utils';
import SaathiAppIcon from '../../../assets/icons/SaathiAppIcon.png';
import arrowRightRed from '../../../assets/icons/arrowRightRed.svg';
import spinner from '../../../assets/icons/spinner.svg';
import phonePreviewSlanted from '../../../assets/images/phonePreviewSlanted.png';

const SignUpPageTemplate = ({
  heading,
  subHeading,
  onSubmit,
  isSubmitting = false,
  deviceType,
  errorMessage,
  showBackButton = true,
  children,
}: {
  heading: string;
  subHeading: string;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  deviceType: 'desktop' | 'mobile';
  errorMessage?: string;
  showBackButton?: boolean;
  children;
}) => {
  return (
    <div
      className={classify([
        'min-h-[100vh] pb-[100px]',
        '[background:linear-gradient(180deg,#FFF_7.41%,rgba(255,255,255,0.50)100%),radial-gradient(98.33%_50%_at_50%_50%,#FBA4DA_0%,#99C7FB_100%)]',
        'sm:pb-[200px]',
      ])}
    >
      <div
        className={classify([
          'flex flex-col items-start px-6 ',
          'sm:mt-[103px] sm:flex-row  sm:justify-center sm:gap-[100px] sm:px-0 ',
          '2xl:mt-[153px] 2xl:gap-[125px]',
        ])}
      >

        {/* RHS on desktop (central/main on mobile): */}
        <div className={classify(['mt-[100px]', 'max-w-[560px] sm:mt-[70px]'])}>
          <h5 className='mb-16 text-2xl sm:text-4xl'>Join the Saathi Savings Programme</h5>
          {/* <Heading className={classify(['mt-[0px]', 'sm:text-[3.5rem]'])} size='base'>
            {heading}
          </Heading> */}
          <h4
            className={classify([
              'text-2xl text-[#B97DCC]',
              'font-telegrafUltraBold mb-1 mt-0 sm:text-3xl',
            ])}
          >
            {heading}
          </h4>

          <Text
            className={classify(['!text-sm', 'font-telegrafRegular !mb-3 !mt-0 sm:!text-base'])}
            color='black'
          >
            {subHeading}
          </Text>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className={classify(['mb-4 flex flex-col gap-2'])}>{children}</div>

            {/* If submit functionality is required show 'Continue' button: */}
            {onSubmit && <SubmitButton isSubmitting={isSubmitting} />}
          </form>

          {errorMessage && (
            <Text className={classify(['!my-2  !text-red-500'])} size='2xs' weight='regular'>
              - Error: {errorMessage}
            </Text>
          )}

          {showBackButton && <BackButton />}
        </div>
      </div>
    </div>
  );
};

const SubmitButton = ({ isSubmitting }) => {
  return (
    <button
      type='submit'
      disabled={isSubmitting}
      className='flex w-full flex-row justify-center rounded-xl bg-black px-4 py-4 text-white'
    >
      {!isSubmitting ? (
        <span className={classify(['text-base leading-4'])}>Continue</span>
      ) : (
        <img src={spinner} className={classify(['h-4'])} />
      )}
    </button>
  );
};

const BackButton = () => {
  let navigate = useNavigate();

  return (
    <button
      className={classify([
        'mt-5 flex cursor-pointer flex-row items-center gap-1 justify-self-end rounded-lg bg-black/[0.2] px-2 py-2',
        'hover:bg-black',
      ])}
      onClick={() => navigate(-1)}
    >
      <img src={arrowRightRed} className={classify(['rotate-180'])} />
      <Text size='2xs' className='!text-sm text-white' weight='semiBold'>
        BACK
      </Text>
    </button>
  );
};

export default SignUpPageTemplate;
