import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'src/sharedComponents/Forms/InputField';
import { classify } from 'src/utils';
import Mixpanel from 'mixpanel-browser';
import SignUpPageTemplate from './index';
import { signupDataContext } from './signUpDataProvider';
import { initialiseSignup } from './utils';

const RegisterEnterDetails = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, setSignUpInfo, setOtp, setPin } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    // reset info if the user pressed back from OTP page:
    setOtp('');
    setPin('');
    setSignUpInfo({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      referralCode: '',
      isExistingBankCustomer: false,
    });
  }, []);
  
  const handleAlphaInputChange =
  (field: 'firstName' | 'lastName') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only alphabetic characters
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(value)) {
      setSignUpInfo({
        ...signUpInfo,
        [field]: value,
      });
    }
  };

  async function processSubmit() {
    if (signUpInfo.phoneNumber.length !== 10) {
      return setErrorMessage('Mobile phone number must be 10 digits long.');
    }
    Mixpanel.track("registration_enter_details");
    Mixpanel.track('Text box - Email', { value: signUpInfo?.email });
    Mixpanel.track('Text box - Mobile', { value: signUpInfo?.phoneNumber });
    Mixpanel.track('is_existing_bank_customer', { value: signUpInfo?.isExistingBankCustomer, phoneNumber: signUpInfo?.phoneNumber });
    setIsSubmitting(true);

    try {
      await initialiseSignup(signUpInfo);
      navigate('/register/EnterOTP');
    } catch (err) {
      console.log('ERROR HITTING REG INITIALISTION', err);
      setErrorMessage(err);
      setIsSubmitting(false);
    }
  }

  return (
    <SignUpPageTemplate
      heading='Create your account'
      subHeading='Let’s get to know you, please give us a few details to get you set up and verify your mobile:'
      onSubmit={processSubmit}
      isSubmitting={isSubmitting}
      deviceType={deviceType}
      errorMessage={errorMessage}
      showBackButton={false}
    >
      <InputField
        label='First Name*'
        autoCapitalize='words'
        autoComplete='given-name'
        pattern='[a-zA-Z]+'
        autoFocus
        required
        value={signUpInfo.firstName}
        onChange={handleAlphaInputChange('firstName')}
      />

      <InputField
        label='Last Name*'
        autoCapitalize='words'
        autoComplete='family-name'
        pattern='[a-zA-Z]+'
        required
        value={signUpInfo.lastName}
        onChange={handleAlphaInputChange('lastName')}
      />

      <InputField
        label='Email*'
        type='email'
        autoComplete='email'
        required
        value={signUpInfo.email}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            email: e.target.value,
          });
        }}
      />

      <InputField
        label='Mobile Number*'
        type='tel'
        pre='+91'
        autoComplete='tel'
        required
        value={signUpInfo.phoneNumber}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            phoneNumber: e.target.value,
          });
        }}
      />

      <InputField
        label='Referral Code'
        type='text'
        defaultValue={signUpInfo.referralCode}
        value={signUpInfo.referralCode}
        onChange={(e) => {
          setSignUpInfo({
            ...signUpInfo,
            referralCode: e.target.value,
          });
        }}
      />

      <div>
        <label htmlFor="existingBankCustomer" className='w-full block appearance-none pt-4 font-telegrafSemiBold focus:!outline-none lg:text-xl mb-2'>Are You an Existing Suryoday Bank Customer?</label>
        <div className="relative peer h-16 flex items-center w-full flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
          <nav className="flex w-full h-full min-w-[240px] flex-row gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">

            <div className="flex ml-[25%] items-center gap-3 w-full p-0 leading-tight transition-all rounded-lg outline-none text-start">
              <input
                checked={signUpInfo.isExistingBankCustomer === true}
                type="radio"
                name="hs-default-radio"
                className="shrink-0 h-5 w-5 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-existingCustomer-yes"
                onChange={(e) => {
                  setSignUpInfo({
                    ...signUpInfo,
                    isExistingBankCustomer: e.target.checked
                  });
                }}
              />
              <p className="block font-telegrafSemiBold text-base antialiased font-medium leading-relaxed lg:text-xl text-blue-gray-400">
                Yes
              </p>
            </div>

            <div className="flex items-center gap-3 w-full p-0 leading-tight transition-all rounded-lg outline-none text-start">
              <input
                checked={signUpInfo.isExistingBankCustomer === false}
                type="radio"
                name="hs-default-radio"
                className="shrink-0 h-5 w-5 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-existingCustomer-no"
                onChange={(e) => {
                  setSignUpInfo({
                    ...signUpInfo,
                    isExistingBankCustomer: !e.target.checked
                  });
                }}
              />
              <p className="block font-telegrafSemiBold text-base antialiased font-medium leading-relaxed lg:text-xl text-blue-gray-400">
                No
              </p>
            </div>

          </nav>
        </div>
      </div>

      <p
        className={classify([
          '!text-xs',
          'font-telegrafRegular ml-6 text-xs leading-[1.1] text-black/50',
        ])}
      >
        Saathi is currently only available to residents of India
      </p>
    </SignUpPageTemplate>
  );
};
export default RegisterEnterDetails;
