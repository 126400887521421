import { useEffect, useRef } from 'react';
import SavingsProgrammeEnterDetails from 'src/pages/SuryodayLanding/JoinSavingsProgramme/SavingsProgrammeEnterDetails';
import { classify } from 'src/utils';
import EarnRewardsOnSection from './components/EarnRewardsOnSection/EarnRewardsOnSection';
import HeroSection from './components/HeroSection/HeroSection';
import mixpanel from 'mixpanel-browser';

const SuryodayLandingPage = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const merchantLogosSectionRef = useRef(null);

  const scrollDownToMerchantLogos = () => {
    merchantLogosSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    mixpanel.track("pageview_sbl_landing");
  }, [])
  

  return (
    <>
      <div
        className={classify([
          'mx-auto [background:linear-gradient(180deg,#FFF_7.41%,rgba(255,255,255,0.50)100%),radial-gradient(98.33%_50%_at_50%_50%,#FBA4DA_0%,#99C7FB_100%)]',
        ])}
      >
        <HeroSection
          deviceType={deviceType}
          scrollDownToMerchantLogos={scrollDownToMerchantLogos}
        />
      </div>
      <div className={classify(['mx-auto'])}>
        {/* 'What you can earn rewards on' section: */}
        <EarnRewardsOnSection
          deviceType={deviceType}
          merchantLogosSectionRef={merchantLogosSectionRef}
        />
      </div>

      <div id='register'>
        <SavingsProgrammeEnterDetails deviceType={deviceType} />
      </div>
    </>
  );
};

export default SuryodayLandingPage;
