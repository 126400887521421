import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import Mixpanel from 'mixpanel-browser';
import InputField from 'src/sharedComponents/Forms/InputField';
import Text from 'src/sharedComponents/Typography/Text';
import { classify } from 'src/utils';
import SignUpPageTemplate from './SignUpPageTemplate';
import { signupDataContext } from './SignupDataProvider';
import { finaliseSignup, initialiseSignup } from './utils';

const SavingsProgrammeEnterOTP = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, otp, setOtp, pin } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect dodgy page visits back to start:
    if (
      !signUpInfo.email ||
      !signUpInfo.firstName ||
      !signUpInfo.lastName ||
      !signUpInfo.phoneNumber
    ) {
      navigate('/suryoday/SavingsProgramme/EnterDetails');
    }
  }, []);

  async function resendOTP() {
    setIsSubmitting(true);
    try {
      await initialiseSignup(signUpInfo).then((res) => {});
    } catch (err) {
      console.log('ERROR HITTING REG INITIALISTION', err);
      setErrorMessage(err);
    }
    setIsSubmitting(false);
  }

  async function processSubmit() {
    if (otp.length !== 6) {
      return setErrorMessage('OTP must be 6 digits.');
    }
    Mixpanel.track("registration_sbl_enter_otp");
    navigate('/suryoday/SavingsProgramme/EnterPin');
  }

  return (
    <SignUpPageTemplate
      heading='Create your account'
      subHeading={`You should have received a one-time-passcode to the mobile number or Email ID please enter it below:`}
      onSubmit={processSubmit}
      deviceType={deviceType}
      errorMessage={errorMessage}
      isSubmitting={isSubmitting}
    >
      <OtpInput
        inputStyle='h-16 lg:h-24 bg-white rounded-2xl shadow-md !w-full'
        containerStyle='justify-between space-x-2 lg:space-x-4 text-5xl'
        inputProps={{ inputMode: 'numeric' }}
        numInputs={6}
        isInputNum
        shouldAutoFocus
        autoComplete='one-time-code'
        value={otp}
        onChange={setOtp}
        className={classify(['mb-2 mt-2', 'sm:my-0'])}
      />

      <Text
        size='sm'
        className={classify(['cursor-pointer', 'hover:underline'])}
        onClick={resendOTP}
      >
        Resend OTP
      </Text>
    </SignUpPageTemplate>
  );
};
export default SavingsProgrammeEnterOTP;
