export interface IdeaCategory {
  category_id: string;
  category_name: string;
  category_description: string;
  idea_id: string;
  merchant_id?: string;
  is_active: boolean;
  image_vertical_url: string;
  image_horizontal_url: string;
  createdAt: string;
  updatedAt: string;
}

export const ideaCategories: IdeaCategory[] = [
  {
    category_id: 'aBcDeFgHiJ',
    category_name: 'Travel',
    category_description:
      'Embark on journeys that create memories, broaden horizons, and illuminate the soul. Travel, where adventure meets self-discovery.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/731217/pexels-photo-731217.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/2087391/pexels-photo-2087391.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'kLmNoPqRsT',
    category_name: 'Home',
    category_description:
      'Transform your living space into a haven. Home is where dreams flourish, memories bloom, and your unique story unfolds.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/2102587/pexels-photo-2102587.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'uVwXyZaBcD',
    category_name: 'Auto',
    category_description:
      'Ride into freedom and exhilaration. Your journey is a canvas; paint it with the wheels of your dreams.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'EfGhIjKlMn',
    category_name: 'Fitness',
    category_description:
      'Sculpt a healthier, vibrant you. Fitness is the key to unlocking energy, resilience, and a life well-lived.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/3225889/pexels-photo-3225889.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/2294361/pexels-photo-2294361.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'oPqRsTuVwX',
    category_name: 'Family',
    category_description:
      'Build bonds that withstand time. Family is a tapestry woven with love, laughter, and shared dreams.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/1456951/pexels-photo-1456951.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/4148842/pexels-photo-4148842.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'yZaBcDeFgH',
    category_name: 'Education',
    category_description:
      'Ignite your mind, shape your future. Education is the compass guiding you through the unexplored realms of knowledge.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/433333/pexels-photo-433333.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/256455/pexels-photo-256455.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'iJkLmNoPqR',
    category_name: 'Entertainment',
    category_description:
      'Craft moments that resonate. Entertainment is the canvas where your stories and emotions come to life.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/2927080/pexels-photo-2927080.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
  {
    category_id: 'sTuVwXyZaB',
    category_name: 'Entrepreneurship',
    category_description:
      'Manifest ideas into reality. Entrepreneurship is the journey where passion, creativity, and perseverance converge.',
    idea_id: '',
    merchant_id: '',
    is_active: true,
    image_vertical_url:
      'https://images.pexels.com/photos/6592547/pexels-photo-6592547.jpeg?auto=compress&cs=tinysrgb&w=800',
    image_horizontal_url:
      'https://images.pexels.com/photos/5922329/pexels-photo-5922329.jpeg?auto=compress&cs=tinysrgb&w=800',
    createdAt: '',
    updatedAt: '',
  },
];
