import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import { classify } from 'src/utils';
import SignUpPageTemplate from './SignUpPageTemplate';
import Mixpanel from 'mixpanel-browser';
import { signupDataContext } from './SignupDataProvider';
import { finaliseSignup } from './utils';

const SavingsProgrammeEnterPin = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  const { signUpInfo, pin, setPin, otp } = useContext(signupDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect dodgy page visits back to start:
    if (
      !signUpInfo.email ||
      !signUpInfo.firstName ||
      !signUpInfo.lastName ||
      !signUpInfo.phoneNumber ||
      !otp
    ) {
      navigate('/suryoday/SavingsProgramme/EnterDetails');
    }
  }, []);

  async function processSubmit() {
    if (pin.length !== 6) {
      return setErrorMessage('PIN must be 6 digits long.');
    }
    console.log('SUBMITTING');
    Mixpanel.track("registration_sbl_enter_pin");
    setIsSubmitting(true);
    try {
      await finaliseSignup(signUpInfo, otp, pin).then((res) => {
        console.log('RESULT FROM FINALISING SIGNUP', res);
        navigate('/suryoday/SavingsProgramme/Finished');
      });
    } catch (err) {
      console.log('ERROR HITTING FINALISATION API', err);
      setErrorMessage(err);
    }
    setIsSubmitting(false);
  }

  return (
    <SignUpPageTemplate
      heading='Enter PIN'
      subHeading='Please type in a 6 digit PIN that will be used to sign into your account on the app:'
      onSubmit={processSubmit}
      deviceType={deviceType}
      errorMessage={errorMessage}
      isSubmitting={isSubmitting}
    >
      <OtpInput
        inputStyle='h-16 lg:h-24 bg-white rounded-2xl shadow-md !w-full'
        containerStyle='justify-between space-x-2 lg:space-x-4 text-5xl'
        inputProps={{ inputMode: 'numeric' }}
        numInputs={6}
        isInputNum
        isInputSecure
        shouldAutoFocus
        autoComplete='one-time-code'
        value={pin}
        onChange={setPin}
        className={classify(['mb-2 mt-2', 'sm:my-0'])}
      />
    </SignUpPageTemplate>
  );
};
export default SavingsProgrammeEnterPin;
