import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

var googleLink =
  process.env.REACT_APP_PROJECT_LIVE_BOOLEAN == 'true'
    ? process.env.REACT_APP_EARLY_PLAY_STORE
    : process.env.REACT_APP_PLAY_STORE;

var appleLink =
  process.env.REACT_APP_PROJECT_LIVE_BOOLEAN == 'true'
    ? process.env.REACT_APP_APP_STORE
    : process.env.REACT_APP_EARLY_APP_STORE;

function App() {
  useEffect(() => {
    if (isAndroid) {
      window.location.href = googleLink;
    } else if (isIOS) {
      window.location.href = appleLink;
    } else {
      window.location.href = 'https://saathi.money';
    }
  }, []);

  return <div className='App'></div>;
}

export default App;