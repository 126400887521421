import { useState, useEffect } from "react";
import "./BrandLogosSection.scss";

//IMPORT ALL IMAGES FROM './brandLogos:
const brandLogoSources = require.context(
  "./brandLogos",
  false,
  /\.(png|jpe?g|svg)$/
);
let brandLogoSourcesFormatted = brandLogoSources
  .keys()
  .map((item) => item.replace("./", ""));

//RANDOMIZE ORDER OF LOGOS
let brandLogosRandomized = [];
while (brandLogosRandomized.length < brandLogoSourcesFormatted.length) {
  let randomIndex = Math.floor(
    Math.random() * brandLogoSourcesFormatted.length
  );
  while (
    brandLogosRandomized.includes(brandLogoSourcesFormatted[randomIndex])
  ) {
    randomIndex = Math.floor(Math.random() * brandLogoSourcesFormatted.length);
  }
  brandLogosRandomized.push(brandLogoSourcesFormatted[randomIndex]);
}

//SPLIT ENTIRE ARRAY OF LOGOS INTO 9 SEPERATE STACKS/CHUNKS:
const chunkSize = Math.floor(brandLogosRandomized.length / 9);
let brandLogoChunks = [];
for (let i = 0; i < brandLogosRandomized.length; i += chunkSize) {
  brandLogoChunks.push(brandLogosRandomized.slice(i, i + chunkSize));
}
if (brandLogoChunks.length > 9) {
  brandLogoChunks[9].forEach((logo, i) => brandLogoChunks[i].push(logo));
  brandLogoChunks.pop();
}

console.log("brand logo chunks", brandLogoChunks);

export default () => {
  let randomAnimationDelays = [];
  (function getUniqueRandomAnimationDelay() {
    let maximumDelay = 8;
    while (randomAnimationDelays.length < maximumDelay + 1) {
      let randomDelay = Math.round(Math.random() * 8);
      while (randomAnimationDelays.includes(randomDelay)) {
        randomDelay = Math.round(Math.random() * 8);
      }
      randomAnimationDelays.push(randomDelay);
    }
  })();

  return (
    <div id="BrandLogosSection">
      {brandLogoChunks.map((logoChunk, chunkIndex) => {
        const chunkDelay = randomAnimationDelays[chunkIndex];
        console.log("CHUNK DELAY", chunkDelay, chunkIndex);

        const Chunk = () => {
          // let [activeImageIndex, setActiveImageIndex] = useState(-1); //so starts out blank
          let [activeImageIndex, setActiveImageIndex] = useState(0);

          useEffect(() => {
            let imageSwapper;
            const myTimeout = setTimeout(function () {
              setActiveImageIndex((currentImgIndex) => {
                return currentImgIndex + 1 < logoChunk.length
                  ? currentImgIndex + 1
                  : 0;
              });
              imageSwapper = setInterval(function () {
                setActiveImageIndex((currentImgIndex) => {
                  return currentImgIndex + 1 < logoChunk.length
                    ? currentImgIndex + 1
                    : 0;
                });
              }, 18 * 1000);
            }, chunkDelay * 2 * 1000);
            return () => {
              clearTimeout(myTimeout);
              clearInterval(imageSwapper);
            };
          }, []);

          return (
            <div key={chunkIndex}>
              {activeImageIndex >= 0 && (
                <img
                  alt="Brand Logos"
                  src={require("./brandLogos/" + logoChunk[activeImageIndex])}
                  style={{
                    animation: `fadeInOut 18s ${chunkDelay * 2}s infinite`,
                  }}
                />
              )}
            </div>
          );
        };
        return <Chunk />;
      })}
    </div>
  );
};
