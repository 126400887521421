import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import getAppIcon from '../../assets/icons/getAppIcon.svg';
import { getMobileOperatingSystem } from '../../utils';
import { AppleAppStoreButton, GooglePlayStoreButton } from '../AppStoreButtons/AppStoreButtons';
import './NavBarMobile.scss';
import SaathiBlackLogoWText from './assets/SaathiBlackLogoWText.png';
import SaathiWhiteLogoWText from './assets/SaathiWhiteLogoWText.png';
import arrowDownBlack from './assets/arrowDownBlack.png';
import arrowDownWhite from './assets/arrowDownWhite.png';
import arrowRightBlack from './assets/arrowRightBlack.png';
import arrowRightWhite from './assets/arrowRightWhite.png';
import navBarMobile1LineBlack from './assets/navBarMobile1LineBlack.png';
import navBarMobile1LineWhite from './assets/navBarMobile1LineWhite.png';
import navBarMobile2LinesBlack from './assets/navBarMobile2LinesBlack.png';
import navBarMobile2LinesWhite from './assets/navBarMobile2LinesWhite.png';

export default function MobileNavBar({ isDevelopersPage, pageLocation, setShowRegOrDLModalBool }) {
  let navigate = useNavigate();
  const location = useLocation();

  let [menuOpen, setMenuOpen] = useState(false);

  function toggleMenuOpen() {
    setMenuOpen(!menuOpen);
    setOpenMainLinks([]);
  }

  let [openMainLinks, setOpenMainLinks] = useState([]);
  function handleMainLinkClick(mainLinkClicked) {
    setOpenMainLinks(
      !openMainLinks.includes(mainLinkClicked)
        ? [...openMainLinks, mainLinkClicked]
        : openMainLinks.filter((mainLink) => mainLink !== mainLinkClicked),
    );
  }

  function handleRouteLinkClick(routeLinkClicked) {
    if (routeLinkClicked === './' && location.pathname.includes('/suryoday')) {
      navigate('/suryoday');
    } else {
      navigate(routeLinkClicked);
    }

    setOpenMainLinks([]);
    setMenuOpen(false);
  }

  const topRightButtonOptions = {
    isDevelopersPage: {
      true: {
        menuOpen: {
          true: navBarMobile1LineWhite,
          false: navBarMobile2LinesWhite,
        },
      },
      false: {
        menuOpen: {
          true: navBarMobile1LineBlack,
          false: navBarMobile2LinesBlack,
        },
      },
    },
  };

  const dropDownArrowOptions = {
    isDevelopersPage: {
      true: {
        open: {
          true: arrowDownWhite,
          false: arrowRightWhite,
        },
      },
      false: {
        open: {
          true: arrowDownBlack,
          false: arrowRightBlack,
        },
      },
    },
  };

  document.body.style.overflow = menuOpen ? 'hidden' : 'visible';

  const projectLiveBoolean = process.env.REACT_APP_PROJECT_LIVE_BOOLEAN === 'true';
  // const projectLiveBoolean = true;

  return (
    <div
      id='fullWidthContainerDiv'
      className={
        'topBarContainer' + (menuOpen ? ' open' : '') + (isDevelopersPage ? ' developersPage' : '')
      }
    >
      {/* Background gradient options/combinations: */}
      <div className='radialPinkBlueGradient' />
      <div className='whiteningLinearGradient' />

      <div id='NavBarMobile'>
        <img
          alt='Mobile nav bar icon'
          src={isDevelopersPage ? SaathiWhiteLogoWText : SaathiBlackLogoWText}
          id='saathiLogo'
          onClick={() => handleRouteLinkClick('./')}
        />

        {(!pageLocation.includes('/LegalDocs/') && !pageLocation.includes('/suryoday')) && (
          <div id='topRightButtonWrapper' onClick={toggleMenuOpen}>
            <img
              alt='Button wrapper'
              src={topRightButtonOptions.isDevelopersPage[isDevelopersPage].menuOpen[menuOpen]}
            />
          </div>
        )}
      </div>

      {menuOpen && (
        <>
          <section id='linksSection'>
            <div className='linksContainer'>
              <a href='/savings-club'>
                <h1>Saathi Savings Club</h1>
              </a>
            </div>

            <div className='linksContainer'>
              <h1 className='subLink' onClick={() => handleRouteLinkClick('/ForBusinesses')}>
                For Businesses
              </h1>
            </div>
            {/* About Us */}
            <div className='linksContainer'>
              <h1 className='subLink' onClick={() => handleRouteLinkClick('/AboutUs')}>
                About Us
              </h1>
            </div>
            {/* Developr */}
            <div className='linksContainer'>
              <h1
                className='subLink'
                onClick={() => handleRouteLinkClick('/Resources/ForDevelopers')}
              >
                Developer
              </h1>
            </div>

            {/* FAQs */}
            {/* <div className='linksContainer'>
              <h1
                className='subLink'
                onClick={() => handleRouteLinkClick('/Resources/FAQ')}
              >
                FAQs
              </h1>
            </div> */}

            {/* <div
              className={
                'linksContainer' +
                (openMainLinks.includes('Resources') ? ' open' : '')
              }
            >
              <h1 onClick={() => handleMainLinkClick('Resources')}>
                Resources
                <img
                  alt='Developer page'
                  src={
                    dropDownArrowOptions.isDevelopersPage[isDevelopersPage]
                      .open[openMainLinks.includes('Resources')]
                  }
                  style={
                    !openMainLinks.includes('Resources')
                      ? {
                          width: '12px',
                        }
                      : {
                          width: '18px',
                        }
                  }
                />
              </h1>
              <div className='dropdown'>
                <h5
                  className='subLink'
                  onClick={() =>
                    handleRouteLinkClick('Resources/ForDevelopers')
                  }
                >
                  For Developers
                </h5>
                <h5
                  className='subLink'
                  onClick={() => handleRouteLinkClick('Resources/FAQ')}
                >
                  FAQ
                </h5>
              </div>
            </div> */}
          </section>

          <div className='fixedFooterContainer'>
            {projectLiveBoolean ? (
              getMobileOperatingSystem() === 'iOS' ? (
                <AppleAppStoreButton />
              ) : getMobileOperatingSystem() === 'Android' ? (
                <GooglePlayStoreButton />
              ) : (
                <div id='getTheAppButton' onClick={() => setShowRegOrDLModalBool(true)}>
                  <span>Get the App</span>
                  <img src={getAppIcon} alt='Get the Saathi app' />
                </div>
              )
            ) : (
              <div id='getTheAppButton' onClick={() => setShowRegOrDLModalBool(true)}>
                <span>Early Access</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
