import { API } from "aws-amplify";

/*
 * shuffle function to take an array and randomly shuffly the contents to produce a new list
 */
export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

// Used like so
var arr = [2, 11, 37, 42];
shuffle(arr);
console.log(arr);

/* fetchArticlesByType fetches a list of article objects by a given type
 *
 * types include: legal, inspiration, article and bucketlist
 *
 * articleTitle, articleContent and articleImageURL are key fields
 *
 * endpoint: {{saathiAPIEndpoint}}/articles/byType?type=legal from aws-exports.js
 *
 */
export async function fetchArticlesByType(articleType) {
  console.log(">>>> Fetching articles of type: bucketlist");

  try {
    const articleList = await API.get(
      "saathiAPI",
      "/articles/byType?type=" + articleType
    );
    console.log(">>>> Article Count", articleList.length);
    articleList.forEach((element) =>
      console.log("  >> Title: " + element.articleTitle)
    );
    return articleList;
  } catch (err) {
    // Something went wrong!
    console.log("---- Error:", err);

    var articleItem = new Object();
    articleItem.articleTitle = "An error has occured"; // title is an error has occured
    articleItem.articleImageURL = ""; // no image is set
    articleItem.articleType = articleType; // articleType of the articleType
    var articleList = [articleItem];

    // Just here for testing that the error object works.
    articleList.forEach((element) =>
      console.log("  >> Title: " + element.articleTitle)
    );

    return articleList;
  }
}

export async function fetchLegalDocument(title) {
  console.log("Current Request: " + title);

  try {
    const legalDocObject = await API.get("saathiAPI", "/articles/byTitle", {
      queryStringParameters: {
        title,
      },
    });
    return legalDocObject;
  } catch (e) {
    let legalDocObject = new Object();
    legalDocObject.updatedAt = "21/12/2022T00:00:00";
    legalDocObject.articleContent =
      "<p>Apologies, we couldn't fetch your article.</p>";
    console.log("Error", e);
    return legalDocObject;
  }
}
