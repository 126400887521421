import { isAndroid, isIOS } from 'react-device-detect';
import {
  default as devEarlyAccessQR,
  default as prodEarlyAccessQR,
} from '../../../assets/images/dev_early_access.svg';
// ^ SHOULD HAVE PROD OPTION FOR: saathi.money/earlyAccess as well (not just pointing straight to dev)
import * as React from 'react';
import Mixpanel from 'mixpanel-browser';
import { useIntercom } from 'react-use-intercom';
import { signupDataContext } from 'src/pages/SuryodayLanding/JoinSavingsProgramme/SignupDataProvider';
import { classify, getMobileOperatingSystem } from 'src/utils';
import {
  AppleAppStoreButton,
  GooglePlayStoreButton,
} from '../../../sharedComponents/AppStoreButtons/AppStoreButtons';
import SignUpPageTemplate from './SignUpPageTemplate';

const webQRCodeImage = process.env.REACT_APP_DEV === 'true' ? devEarlyAccessQR : prodEarlyAccessQR;
const altTextWeb = 'QR Code for Saathi App';

const SavingsProgrammeFinished = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
  console.log('IS ANDROID', getMobileOperatingSystem() === 'Android');

  const { signUpInfo } = React.useContext(signupDataContext);
  const { update } = useIntercom();
  React.useEffect(() => {
    Mixpanel.track("registration_sbl_success");
  },[])
  React.useEffect(() => {
    update({
      email: signUpInfo.email,
      phone: `+91 ${signUpInfo.phoneNumber}`,
      customAttributes: {
        'Do you current have any savings?': signUpInfo.currentSavings,
        'What are you saving for / would you like to save for?': signUpInfo.savingFor,
        'What would you like to gain from this programme?': signUpInfo.gainFromProgramme,
      },
    });
  }, [signUpInfo, update]);

  return (
    <SignUpPageTemplate
      heading='SUCCESS'
      subHeading={
        deviceType === 'desktop'
          ? 'You are successfully registered to Saathi! Download our app by scanning the code below on your mobile device and login with your PIN to get started saving today:'
          : 'You are successfully registered to Saathi! Download our app by pressing the button below and login with your PIN to get started with your savings:'
      }
      deviceType={deviceType}
      showBackButton={false}
    >
      <div className={classify(['mx-auto my-4 w-[200px]', 'sm:mx-auto sm:my-0 sm:w-auto'])}>
        {deviceType === 'desktop' ? (
          <img
            id='qrCode'
            src={webQRCodeImage}
            alt={altTextWeb}
            className={classify(['mx-auto mb-0 mt-4 w-[300px]'])}
          />
        ) : isAndroid ? (
          <GooglePlayStoreButton />
        ) : (
          <AppleAppStoreButton />
        )}
      </div>
    </SignUpPageTemplate>
  );
};
export default SavingsProgrammeFinished;
