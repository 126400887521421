import React from "react";
import {useEffect } from "react";
import './index.scss';
import Hero from "./Hero/index";
import Why from "./Why";
//import Testimonials from "./Testimonials";


export default function SavingsClub({}) {
  useEffect(() => {
	  document.body.classList.add('club');
	  return () => { document.body.classList.remove('club'); };
  }, []);
  
  return (
	<>
		<Hero />
		<Why />
		{/* <Testimonials /> */}
		<br/><br/><br/><br/><br/><br/>
	</>
  );
}