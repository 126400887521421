import './ContactUsModal.scss';
import handUpPNG from '../../assets/icons/handUp.png';
import closePNG from '../../assets/icons/close.png';

export default function ContactUs({ setShowContactUsModalBool }) {
  let email = 'support@besaathi.com';
  let phone = '+91 80 694 90468';
  let address =
    '502 Sagar Fortune, 184 Waterfield Road, Off Linking Road, Bandra West, Mumbai, 400 050';
  let chat = "Chat to us by tapping the pink 'Meet Saathi' button on any page";

  return (
    <div id='modalContainer'>
      <div id='opacifyingDiv'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />
      </div>
      <div id='RegisterModal'>
        <img
          alt='Close icon'
          src={closePNG}
          id='closePNG'
          onClick={() => setShowContactUsModalBool(false)}
        />
        <img src={handUpPNG} id='handUpPNG' alt='Hand up icon' />
        <h1>Contact Us</h1>
        <p>
          There a many ways to get hold of us at Saathi. We have Chat,
          Whatsapp/Phone, Address and Email contact points:
          <br />
        </p>

        <div id='contactDetailsGrid'>
          <div className='label'>
            <span>Email:</span>
          </div>
          <div className='data'>
            <a href={`mailto:${email}`}>{email}</a>
          </div>

          <div className='label'>
            <span>Address:</span>
          </div>
          <div className='data'>
            <span>{address}</span>
          </div>

          <div className='label'>
            <span>Phone:</span>
          </div>
          <div className='data'>
            <span>{phone}</span>
          </div>

          <div className='label'>
            <span>Chat:</span>
          </div>
          <div className='data'>
            <span>{chat}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
