import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

// if the QR code is beta, then link to the beta url:
const googleLink =
  process.env.REACT_APP_BETA_QR === 'false'
    ? process.env.REACT_APP_PLAY_STORE
    : process.env.REACT_APP_EARLY_PLAY_STORE;
console.log('Google link: ' + googleLink);

// if the QR code is beta, then link to the beta url:
console.log('IS BETA?', process.env.REACT_APP_BETA_QR);
const appleLink =
  process.env.REACT_APP_BETA_QR === 'false'
    ? process.env.REACT_APP_APP_STORE
    : process.env.REACT_APP_EARLY_APP_STORE;
console.log('Apple link: ' + appleLink);

// if the QR code is used on the web come here:
const webLink =
  process.env.REACT_APP_DEV === 'true'
    ? 'http://dev.saathi.money/earlyaccessweb'
    : 'http://saathi.money/earlyaccessweb';
console.log('Web link: ' + webLink);

const App = () => {
  // switch location based on type of device
  useEffect(() => {
    if (isAndroid) {
      window.location.href = googleLink;
    } else if (isIOS) {
      window.location.href = appleLink;
    } else {
      window.location.href = webLink;
    }
  }, []);

  return <div className='App'></div>;
};

export default App;
