export default {
  'General Questions': [
    {
      question: 'Is Saathi a bank?',
      answer: (
        <ul>
          <p>
            <li>
              Saathi is not a bank. It's a financial technology platform that
              offers products and services to help you manage your money better.
              It partners with banks and other financial institutions to provide
              innovative ways to spend, save, and be rewarded for good financial
              behavior.
            </li>
          </p>
        </ul>
      ),
    },
    {
      question: 'Do I need to do KYC?',
      answer: (
        <p>
          <ul>
            <li>
              Yes. As per the Reserve Bank of India (RBI) guidelines, in order
              for banks or NBFCs, KYC process must be mandatorily completed. But
              do not worry! This is a one-time process and only requires a
              couple of minutes of your time. You simply have to enter your
              details and verify them directly on Saathi's app.
            </li>
          </ul>
        </p>
      ),
    },
    {
      question: 'What information is needed as proof?',
      answer: (
        <p>
          <ul>
            <li>
              You will need your <strong>PAN number</strong>,{' '}
              <strong>Aadhaar ID</strong> and registered{' '}
              <strong>Aadhaar Mobile Number</strong>
              and the ability to take a selfie on your phone. We will check your
              details against the national PAN and Aadhaar register.
            </li>
          </ul>
        </p>
      ),
    },
    {
      question: 'What sort of account will I get?',
      answer: (
        <p>
          <ul>
            <li>
              You will receive a flexible savings account that pays interest on
              any savings held. The account will be insured up to 5 Lakh under
              the RBI scheme and you can access your saved money at any time.
              Along with your account, you will also receive a virtual card that
              you can use to purchase any products you save towards using
              Saathi.
            </li>
          </ul>
        </p>
      ),
    },
    {
      question: 'Can I add my virtual card to Google or Apple Pay?',
      answer: (
        <p>
          <ul>
            <li>
              Our team is working hard to bring this feature to you, keep an eye
              out!
            </li>
          </ul>
        </p>
      ),
    },
    {
      question: 'What is the Savings Club?',
      answer: (
        <p>
          <ul>
            <li>
              Saathi Savers are our amazing early users who joined the app
              right at the start to test the way for everyone else - they are a
              massive help in trying out features, app flows and proving that
              saving really is rewarding. You know who you are, and we thank
              you!
            </li>
          </ul>
        </p>
      ),
    },
  ],
  'Save Now, Pay Later': [
    {
      question: 'What is Save now, Pay later?',
      answer: (
        <ul>
          <p>
            <li>
              Saathi offers a "Save Now, Pay Later" program where you can save
              money now for a future purchase. We have partnerships with various
              merchants to provide you with the best options for your savings
              goals. Just select a merchant to link your goal to and receive
              rewards or cashback amounts.
            </li>
          </p>
        </ul>
      ),
    },
    {
      question: 'When will I get my interest, reward or cashback?',
      answer: (
        <ul>
          {' '}
          <p>
            <li>
              You will receive your cashback 45-75 days after your purchase,
              once it is confirmed that you are keeping the item you bought.
            </li>{' '}
          </p>
          <p>
            <li>
              <span>Rewards </span>are paid at the time of purchase against what
              you are buying (either in the form of a voucher or direct
              discount) - in Saathi, we know what you're going to be paid and
              can quickly adjust the total amount you need to save as a result.
            </li>{' '}
          </p>
          <p>
            <li>
              <span>Interest </span>is paid at the end of each month against
              your savings and goal balances. If you have money saved for part
              of a month, or your goal completes during the month, your interest
              will be calculated to that point. You may have to wait until the
              end of a given month to receive your interest.
            </li>{' '}
          </p>
        </ul>
      ),
    },
    {
      question: `I didn't receive my reward/cashback/interest, what can I do?`,
      answer: (
        <p>
          <ul>
            <li>
              If you have not received your reward, cashback, or interest, don't
              worry, we're here to assist you. You can reach out to us by
              sending an email to support@besaathi.com or by clicking the help
              button in the app to chat with our team. Our support team is
              available Monday to Saturday from 9 am to 6 pm to address any
              concerns or queries you may have.
            </li>
          </ul>
        </p>
      ),
    },
  ],
  'For Merchants': [
    {
      question:
        'How do I list my products or use Saathi save for my customers?',
      answer: (
        <p>
          <ul>
            <li>
              If you're interested in using Saathi to offer your products to
              customers, we invite you to visit our merchants section for more
              information on how to get started. We offer various options to
              help you make the most of our platform, whether that means listing
              your products directly in the Saathi app or deploying a link to
              Saathi save on your own website or store. We're excited to work
              with you and explore the possibilities!
            </li>
          </ul>
        </p>
      ),
    },
    {
      question:
        'I need to make an update to my products or offers, how can I do this?',
      answer: (
        <p>
          <ul>
            <li>
              For custom implementation or shopify store owners, you can quickly
              do this yourself against your product and offer mapping.
            </li>

            <li>
              For in-app listed products, connect with your Saathi Customer
              Success agent and we'll get this sorted for you quickly, simply
              let us know what needs updating and we'll update it into the
              product database.
            </li>
          </ul>
        </p>
      ),
    },
    {
      question:
        'Do you have a merchant admin panel so I can make my own changes?',
      answer: (
        <p>
          <ul>
            <li>
              The team is currently working hard to bring an admin tool so you
              can make your own changes quickly and easily - stay on the watch!
            </li>
          </ul>
        </p>
      ),
    },
    {
      question: `My customers haven't received their reward or cashback, what can I advise them?`,
      answer: (
        <p>
          <ul>
            <li>
              Simply ask your customer to contact support@besaathi.com or use
              the help section in the app and we'll find out what is going on -
              if you want to be updated on their case, let us know at
              merchants@besaathi.com or through your customer success agent.
            </li>
          </ul>
        </p>
      ),
    },
  ],
};
