import { API } from 'aws-amplify';
import { SignupInfo } from './signUpDataProvider';

export async function initialiseSignup(signupDetails: SignupInfo) {
  await API.post('saathiAPI', '/user/signup', {
    body: {
      ...signupDetails,
      dialCode: '91',
      // dialCode: '44', // HACK
    },
  })
    .then((res) => {})
    .catch((err) => {
      throw err.response.data.message;
    });
}

export async function finaliseSignup(signupDetails: SignupInfo, otp: string, pin: string) {
  const mobileNumber = signupDetails.phoneNumber;
  delete signupDetails.phoneNumber;
  await API.post('saathiAPI', '/user/finaliseRegistration', {
    body: {
      ...signupDetails,
      dialCode: '91',
      // dialCode: '44', // HACK
      mobileNumber,
      otpSubmission: otp,
      newPIN: pin,
    },
  })
    .then((res) => {})
    .catch((err) => {
      console.log('ERROR FINALISING REGISTRATION', err.response.data);
      throw err.response.data;
    });
}
