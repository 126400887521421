import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

// Define the SignupInfo type for TypeScript
export type SignupInfo = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  referralCode?: string;
  isExistingBankCustomer?: boolean;
};

export const signupDataContext = createContext<{
  signUpInfo: SignupInfo;
  setSignUpInfo: React.Dispatch<React.SetStateAction<SignupInfo>>;
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  pin: string;
  setPin: React.Dispatch<React.SetStateAction<string>>;
}>({
  signUpInfo: { email: '', firstName: '', lastName: '', phoneNumber: '' },
  setSignUpInfo: () => {},
  otp: '',
  setOtp: () => {},
  pin: '',
  setPin: () => {},
});

export const SignupDataProvider = () => {
  const [signUpInfo, setSignUpInfo] = useState<SignupInfo>({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    referralCode: '',
    isExistingBankCustomer: false
  });
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');

  return (
    <signupDataContext.Provider value={{ signUpInfo, setSignUpInfo, otp, setOtp, pin, setPin }}>
      <Outlet />
    </signupDataContext.Provider>
  );
};
