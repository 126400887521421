import failureImg from '../../assets/images/flexxpay_failure.png';
import { useEffect } from 'react';
import './flexxpay.scss';

function onClose() {
  window.closeCashfreeModal();
}

export default function FlexxPayCashfreeFailure({}) {
  // Assign success or faliure variable as soon as page loads (so that my Flexxpay widget can access it regardless if they click the 'Close' button here on manually close the modal in my widget)
  useEffect(() => {
    window.cashfreeResult = 'failure';
  }, []);

  return (
    <section id="flexxpay_fail">
      <p className="centerAlign">
        Sorry, we didn't create your Flexxpay subscription this time.
        <br />
        <b id="pleaseTryAgain">Please try again.</b>
      </p>

      <img src={failureImg} alt="Subscription failed" />

      <div>
        <button onClick={onClose}>Close</button>
      </div>

      <p className="smallText">
        Any queries, please contact{' '}
        <a href="https://mailto:support@besaathi.com">support</a>.
      </p>
    </section>
  );
}
