import { useState } from 'react';
import closePNG from '../../assets/icons/close.png';
import {
  default as devEarlyAccessQR,
  default as prodEarlyAccessQR,
} from '../../assets/images/dev_early_access.svg';
// ^ SHOULD HAVE PROD OPTION FOR: saathi.money/earlyAccess as well (not just pointing straight to dev)
import './DownloadAppModal.scss';
import androidLogo from './assets/android_logo.png';
import iosLogo from './assets/ios_logo.png';

const webQRCodeImage = process.env.REACT_APP_DEV === 'true' ? devEarlyAccessQR : prodEarlyAccessQR;

const iOSLink =
  process.env.REACT_APP_BETA_QR === 'false'
    ? process.env.REACT_APP_APP_STORE
    : process.env.REACT_APP_EARLY_APP_STORE;
const androidLink =
  process.env.REACT_APP_BETA_QR === 'false'
    ? process.env.REACT_APP_PLAY_STORE
    : process.env.REACT_APP_EARLY_PLAY_STORE;

const altTextWeb = 'QR Code for Saathi App';

export default ({ setShowRegOrDLModalBool }) => {
  let [qrCodeSelected, setQRCodeSelected] = useState('appStore'); // 'appStore' or 'googlePlayStore'

  return (
    <div id='modalContainer'>
      <div id='opacifyingDiv'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />
      </div>

      <div id='DownloadAppModal'>
        {/* Close button top right: */}
        <img
          src={closePNG}
          id='closePNG'
          alt='Close Icon'
          onClick={() => setShowRegOrDLModalBool(false)}
        />

        {/* ONLY DESKTOPS WILL SEE THIS COMPONENT SO JUST SHOW GENERAL QR CODE FOR MOBILE SCANNING: */}
        <img id='qrCode' src={webQRCodeImage} alt={altTextWeb} />

        {/* <div id='storeLogos'>
          <div id='imagesContainer'>
            <a href={iOSLink}>
              <img alt='App Store Icon' className={qrCodeSelected === 'appStore'} src={iosLogo} />
            </a>
            <a href={androidLink}>
              <img
                alt='Play Store Icon'
                className={qrCodeSelected === 'googlePlayStore'}
                src={androidLogo}
              />
            </a>
          </div>
        </div> */}

        <h2>Download Saathi</h2>
        <p>Scan the code above with your phone!</p>
      </div>
    </div>
  );
};
