//import DownloadSaathiFooter from '../../sharedComponents/DownloadSaathiFooter/DownloadSaathiFooter';
import './AboutUsPage.scss';
import ManifestoAnimation from './ManifestoAnimation/ManifestoAnimation';
import ManifestoScroll from './ManifestoScroll/ManifestoScroll';
import TeamProfileIcons from './TeamProfileIcons/TeamProfileIcons';

export default function AboutUs({
  setShowRegOrDLModalBool,
  setShowContactUsModalBool,
}) {
  console.log('setShowContactUsModalBool ' + setShowContactUsModalBool);
  return (
    <main id='AboutUsPage'>
      <section className='topGradientContainer'>
        <div className='radialPinkBlueGradient' />
        <div className='whiteningLinearGradient' />

        <div className='titleArea'>
          <h5>MEET US</h5>
          <h1>
            We are Saathi
            <br />
            <span>Together we grow</span>
          </h1>
          <p>
            We help our users build a positive relationship with money by
            <br />
            rewarding them for saving. Be part of the movement and offer
            <br />
            rewards for your products through Saathi
          </p>
        </div>

        <ManifestoAnimation />
        {/* <ManifestoScroll /> */}
      </section>

      <section id='comeSayHiSection'>
        <h1>Come say hi!</h1>
        <p>
          We are a technically-minded group of thinkers and doers focused on our
          goal to help others build a more
          <br />
          sustainable and rewarding relationship with their money.
        </p>

        <TeamProfileIcons />
      </section>

      {/* <DownloadSaathiFooter setShowRegOrDLModalBool={setShowRegOrDLModalBool} /> */}
    </main>
  );
}
