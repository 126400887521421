// import { classify } from 'helpers/classify';
// import { ultraBold } from 'helpers/fonts';
// import { TextWeight, useTextWeight } from 'hooks/useTextWeight';

import { classify } from 'src/utils';

const variants = {
  size: {
    sm: 'lg:text-xl',
    base: 'text-lg lg:text-2xl',
  },
};

const fonts = {
  regular: 'font-telegrafRegular',
  semiBold: 'font-telegrafSemiBold',
  ultraBold: 'font-saathiUltraBold',
};

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: keyof typeof variants.size;
  weight?: keyof typeof fonts;

  label?: string;
  pre?: string;
}

const InputField = ({ size = 'base', weight = 'semiBold', label, pre, ...props }: Props) => {
  const _weight = fonts[weight];

  return (
    <label className='relative flex cursor-text flex-row-reverse gap-x-3 rounded-2xl bg-white px-6 shadow-md'>
      <input
        className={classify([
          'peer block h-16 w-full appearance-none focus:!outline-none',
          variants.size[size],
          _weight,
          label ? (pre ? 'pt-4' : 'pt-4 transition-all placeholder-shown:pt-0') : undefined,
          label && 'placeholder-transparent',
        ])}
        placeholder={label}
        {...props}
      />

      {pre ? (
        <span
          className={classify([
            'flex h-16 items-center text-lg lg:text-2xl',
            variants.size[size],
            _weight,
            label ? (pre ? 'pt-4' : 'pt-4 transition-all peer-placeholder-shown:pt-0') : undefined,
          ])}
        >
          {pre}
        </span>
      ) : null}

      {label ? (
        <span
          className={classify([
            'pointer-events-none absolute inset-0 flex origin-top-left -translate-y-3 items-center px-6 text-xs opacity-40',
            label && !pre
              ? 'transition-all peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base lg:peer-placeholder-shown:text-xl'
              : undefined,
            // ultraBold,
            'font-saathiUltraBold',
          ])}
        >
          {label}
        </span>
      ) : null}
    </label>
  );
};
export default InputField;
