import { classify } from 'src/utils';
import './CategoryCards.scss';
import { IdeaCategory, ideaCategories } from './mockData';

type CategoryWTilt = IdeaCategory & {
  tiltAngle?: number;
};

const CategoryCards = ({ deviceType }) => {
  //const Component = deviceType === 'mobile' ? CategoryCardsMobile : CategoryCardsDesktop;
  return <CategoryCardsDesktop ideaCategories={ideaCategories} />;
};
export default CategoryCards;

// const CategoryCardsMobile = ({ ideaCategories }) => {
//   return (
//     <div
//       className={classify([
//         'gap-[5vw] px-[32px] pb-[20px]', // vs 5vw
//         'mx-[-16px] mb-[49px] flex snap-x snap-mandatory flex-row overflow-y-clip overflow-x-scroll',
//       ])}
//     >
//       {ideaCategories.map((category, i) => {
//         return <CategoryCard category={category} key={i} />;
//       })}
//     </div>
//   );
// };

const CategoryCardsDesktop = ({ ideaCategories }) => {
  const ideaCategoriesWTilts: CategoryWTilt[] = ideaCategories.map((category) => {
    return {
      ...category,
      // Generate random tilt for the card (between -4 and 4 deg):
      tiltAngle: Math.random() * 4 * (Math.random() < 0.5 ? -1 : 1),
    };
  });

  const cardWidth = 320;
  const cardQuantity = ideaCategories.length * 2;
  const gapBetweenCards = 20;

  return (
    <div
      id='topCategoryCardsSlider'
      className={classify([
        'relative mx-[-16px] mb-28 overflow-x-clip overflow-y-visible',
        'sm:mx-0',
      ])}
    >
      <div
        className={classify([
          'slideTrack',
          'flex overflow-y-visible hover:![animation-play-state:paused]',
        ])}
        style={{
          gap: `${gapBetweenCards}px`,
          width: `calc((${cardWidth}px + ${gapBetweenCards}px) * ${cardQuantity})`,
        }}
      >
        {/* MAP THE ROW OUT TWICE FOR ANIMATION PURPOSES: */}
        {[...ideaCategoriesWTilts, ...ideaCategoriesWTilts].map((category, i) => {
          return <CategoryCard category={category} key={i} />;
        })}
      </div>
    </div>
  );
};

const CategoryCard = ({ category }: { category: CategoryWTilt }) => {
  return (
    <a
      // href={`/ideaCategories/${category.category_name}`}
      className={classify(['shrink-0'])}
      style={{ transform: category.tiltAngle && `rotate(${category.tiltAngle}deg)` }}
    >
      <div
        className={classify([
          'snap-center snap-always',
          'box-border w-[80vw]',
          'relative isolate aspect-[0.77] shrink-0 transform overflow-hidden rounded-[24px] pl-[23px] pt-[35px] shadow-[0px_4px_16px_0px_#00000052] transition-transform duration-[250ms]',
          'sm:w-[320px] sm:pl-[32px] sm:pt-[37px] sm:hover:-translate-y-2',
          '2xl:w-[400px]',
        ])}
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 37.76%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 31.37%), url("${category.image_vertical_url}") no-repeat center center/ cover`,
          backgroundBlendMode: 'normal, overlay, normal, normal',
        }}
      >
        <h4
          className={classify([
            'font-telegrafSemiBold relative z-10 hyphens-auto break-words text-[2.5rem] leading-[1] text-white',
            'sm:text-[2.5rem] sm:leading-10',
          ])}
        >
          {category.category_name}
        </h4>
      </div>
    </a>
  );
};
