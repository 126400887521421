import './ManifestoAnimation.scss';
import { useEffect, useRef } from 'react';

const lerp = (a, b, t) => a + t * (b - a);

export default function ManifestoAnimation() {
  const svg = useRef();

  useEffect(() => {
    if (!svg.current) return;
    /** @var {HTMLObjectElement} el */
    const el = svg.current;
    const parent = el.parentElement;
    const playerRef = { current: null };

    const onScroll = () => {
      const svgRect = el.getBoundingClientRect();
      const rect = parent.parentElement.getBoundingClientRect();

      const p = Math.min(
        1,
        Math.max(0, window.scrollY - (window.scrollY + rect.top)) /
          (rect.height - window.innerHeight)
      );
      const l = lerp(rect.width * 0.5, -(svgRect.width - rect.width * 0.75), p);
      el.style.setProperty('--l', l + 'px');
      playerRef.current?.seek?.(p * 100);
    };

    const io = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) window.addEventListener('scroll', onScroll, true);
      else window.removeEventListener('scroll', onScroll, true);
    });
    io.observe(parent);

    (async () => {
      let player;

      do {
        player = el?.contentDocument?.documentElement?.svgatorPlayer;
        if (!player) await new Promise((r) => setTimeout(r, 150));
      } while (!player);

      const onReady = (player) => {
        playerRef.current = player;
      };

      if (player.totalTime) onReady(player);
      else player.ready(onReady);
    })();

    return () => {
      io.disconnect();
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [svg]);

  return (
    <div id='ManifestoAnimation'>
      <div>
        <object
          ref={svg}
          type='image/svg+xml'
          data='/svg/manifesto.svg'
          aria-label='The Saathi Manifesto'
        />
      </div>
    </div>
  );
}
