import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBarMobile from './NavBarMobile';
import './TopNavBar.scss';
import SaathiBlackLogoWText from './assets/SaathiBlackLogoWText.png';
import { ReactComponent as SaathiLoopsSVG } from './assets/SaathiLoops.svg';
import SaathiWhiteLogoWText from './assets/SaathiWhiteLogoWText.png';
import profileIconBlack from './assets/profileIconBlack.svg';
import profileIconWhite from './assets/profileIconWhite.svg';
import { ReactComponent as SmallArrowDownSVG } from './assets/smallArrowDown.svg';

export default function TopNavBar({
  pageLocation,
  deviceType,
  setShowRegOrDLModalBool,
  projectLiveBoolean,
}) {
  let navigate = useNavigate();
  let [mainLinkClicked, setMainLinkedClicked] = useState(null);

  const location = useLocation();

  //To close the additional link dropdowns if they've been opened but user clicks elsewhere:

  let dropDownLinksRef = useRef();
  // let checkForClickOutsideDropDown = (event) => {
  //   console.log(dropDownLinksRef);
  //   if (
  //     dropDownLinksRef.current &&
  //     !dropDownLinksRef.current.contains(event.target)
  //   ) {
  //     setMainLinkedClicked(null);
  //   }
  // };
  // useEffect(() => {
  //   //Add the following event listener so that when a user clicks away from the drop down languages list: the list is closed.
  //   document.addEventListener('click', checkForClickOutsideDropDown, true);
  // }, []);
  // let aboutMainLinkRef = useRef();
  // let aboutDropDownLinksRef = useRef();
  // let toolsMainLinkRef = useRef();
  // let toolsDropDownLinksRef = useRef();
  // let checkForClickOutsideDropDown = (event) => {
  //   console.log(aboutMainLinkRef, event.target);
  //   if (aboutMainLinkRef.current?.contains(event.target)) {
  //     console.log(
  //       'running about click',
  //       mainLinkClicked,
  //       mainLinkClicked !== 'About' ? 'About' : null
  //     );
  //     setMainLinkedClicked(mainLinkClicked !== 'About' ? 'About' : null);
  //   } else if (aboutDropDownLinksRef.current?.contains(event.target)) {
  //     setMainLinkedClicked(null);
  //   } else if (toolsMainLinkRef.current?.contains(event.target)) {
  //     setMainLinkedClicked('Resources');
  //   }
  // };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  let isDevelopersPage = pageLocation === '/Resources/ForDevelopers';
  let isHome = pageLocation === '/';

  if (
    pageLocation === '/cfsub/success' ||
    pageLocation === '/cfsub/failure' ||
    pageLocation === '/cfsub/flexxpayfailure' ||
    pageLocation === '/cfsub/flexxpaysuccess' || pageLocation === '/vkyc/success' || pageLocation === '/vkyc/failure'
  ) {
    return null;
  }

  return deviceType === 'desktop' ? (
    // NAV BAR DESKTOP VERSION:
    <>
      <img
        id='SaathiLogo'
        alt='Saathi Logo'
        src={isDevelopersPage ? SaathiWhiteLogoWText : SaathiBlackLogoWText}
        onClick={() => {
          console.log(location);

          if (location.pathname.includes('/suryoday')) {
            navigate('/suryoday');
          } else {
            navigate('/');
          }
          setMainLinkedClicked(null);
          return;

          navigate('/');
          setMainLinkedClicked(null);
        }}
      />
      <div id='dev'>{process.env.REACT_APP_DEV == 'true' ? 'Dev' : ''}</div>

      {/* Dont show man right bar on any of the legal docs pages: */}
      {(!pageLocation.includes('/LegalDocs/') && !pageLocation.includes('/suryoday')) && (
        <div
          id='rightHandNavBar'
          className={(isDevelopersPage && 'developersPage') + ' ' + (scroll && 'scrolled')}
        >
          {!isHome && (
            <div
              id='saathiHomeButton'
              className={pageLocation === '/' ? 'active' : ''}
              onClick={() => navigate('/')}
            >
              <SaathiLoopsSVG id='SaathiLoopsSVG' />
            </div>
          )}
          <div className='navButton' id='savingsClubLink'>
            <a href='/savings-club'>
              <img
                alt='Profile Icon'
                src={isDevelopersPage ? profileIconWhite : profileIconBlack}
                id='profileIcon'
              />
              <span>Savings Club</span>
            </a>
          </div>
          {/* 'SAATHI MERCHANTS' LINK: */}
          <div className={'navButton' + (pageLocation.includes('/ForBusinesses') ? ' active' : '')}>
            <span onClick={() => navigate('/ForBusinesses')}>For Businesses</span>
          </div>
          {/* 'ABOUT' OPTIONS: */}
          <div className={'navButton' + (pageLocation.includes('/AboutUs') ? ' active' : '')}>
            <span onClick={() => navigate('/AboutUs')}>About Us</span>
          </div>
          {/*21/11/2023 - hidden until new component delivered*/}
          {/* 'DEVELOPER' OPTIONS: */}
          {/* <div
            className={
              'navButton' +
              (pageLocation.includes('/Resources/ForDevelopers')
                ? ' active'
                : '')
            }
          >
            <span onClick={() => navigate('/Resources/ForDevelopers')}>
              Developers
            </span>
          </div> */}
          {/* 'FAQs' OPTIONS: */}
          {/* <div
            className={
              'navButton' +
              (pageLocation.includes('/Resources/FAQ') ? ' active' : '')
            }
          >
            <span onClick={() => navigate('/Resources/FAQ')}>FAQs</span>
          </div> */}
          {/* 'RESOURCES' OPTIONS:
          <div
            className={
              'navButton' +
              (pageLocation.includes('/Resources/') ||
              mainLinkClicked === 'Resources'
                ? ' active'
                : '') +
              (mainLinkClicked === 'Resources' ? ' dropdownOpen' : '')
            }
            onClick={() => {
              setMainLinkedClicked(
                mainLinkClicked !== 'Resources' ? 'Resources' : null
              );
            }}
          >
            <span>
              Resources
              <SmallArrowDownSVG
                fill={isDevelopersPage ? 'white' : 'black'}
                className='smallArrowDown'
              />
            </span>
            {mainLinkClicked === 'Resources' && (
              <div className='linkOptions' ref={dropDownLinksRef}>
                <span
                  className={
                    pageLocation.includes('/Resources/ForDevelopers')
                      ? 'active'
                      : ''
                  }
                  onClick={() => navigate('/Resources/ForDevelopers')}
                >
                  For Developers
                </span>
                <span
                  className={
                    pageLocation.includes('/Resources/FAQ') ? 'active' : ''
                  }
                  onClick={() => navigate('/Resources/FAQ')}
                >
                  FAQ
                </span>
              </div>
            )}
          </div> */}
          {/* JOIN WAITLIST LINK: */}
          <a
            className='navButton'
            id='joinWaitlistButton'
            href='/register'
          >
            <span>
              {pageLocation === '/Merchants'
                ? 'Get Started'
                : projectLiveBoolean
                  ? 'Get Started' // update to Get the App
                  : 'Early Access'}
            </span>
          </a>
        </div>
      )}
    </>
  ) : (
    //NAV BAR MOBILE VERSION:
    <NavBarMobile
      isDevelopersPage={isDevelopersPage}
      pageLocation={pageLocation}
      setShowRegOrDLModalBool={setShowRegOrDLModalBool}
    />
  );
}
