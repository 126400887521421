import './SavingsClubBanner.scss';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import person1 from './assets/person1.png';
import person2 from './assets/person2.png';
import person3 from './assets/person3.png';
import person4 from './assets/person4.png';
import person5 from './assets/person5.png';
import person6 from './assets/person6.png';
import person7 from './assets/person7.png';
import person8 from './assets/person8.png';
//import arrowRightWhite from './assets/arrowRightWhite.png';
import smallArrowRightWhite from './assets/smallArrowRightWhite.png';

export default function PageForDevelopers ({ setShowRegOrDLModalBool }) {
  let navigate = useNavigate();
  const projectLiveBoolean =
    process.env.REACT_APP_PROJECT_LIVE_BOOLEAN === 'true';

  return (
    <div id="SavingsClubBanner">
      <div className="leftSide">
        <img src={person1} alt="Savings Club person 1" />
        <img src={person2} alt="Savings Club person 2" />
        <img src={person3} alt="Savings Club person 3" />
        <img src={person4} alt="Savings Club person 4" />
      </div>

      <div className="middle">
        <h1>Join the Savings Club</h1>
        <p>
          Join a community of like-minded savers, helping shape the future of
          rewarding saving and conscious consumerism.
        </p>
        <br/>

       {/* // {projectLiveBoolean === 'true' || projectLiveBoolean === 'false' ? ( */}
          {/* //If project is live then clicking this button will take them to founder's page: */}
          {/* <a href="https://saathi.money/savings-club" target="_blank" rel="noreferrer"> */}
          <button onClick={() => {
          navigate("savings-club");
        }}>Join the savings club</button>

            {/* <button onClick=() => {
          navigate("/")}>
              Sign up for free
              <img src={smallArrowRightWhite} alt="Small arrow right"/>
            </button>
          </a> */}
       {/* // ) : (
        //   //Otherwise it will open up the register modal:
        //   <button onClick={() => setShowRegOrDLModalBool(true)}>
        //     Sign up for free
        //     <img src={smallArrowRightWhite} alt="Small arrow right" />
        //   </button>
        // )} */}
      </div>

      <div className="rightSide">
        <img src={person5}  alt="Savings Club person 5"/>
        <img src={person6}  alt="Savings Club person 6"/>
        <img src={person7}  alt="Savings Club person 7"/>
        <img src={person8}  alt="Savings Club person 8"/>
      </div>
    </div>
  );
}
